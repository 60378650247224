import React, { useEffect } from 'react'
import Modal from '@mui/material/Modal';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Autocomplete from '@mui/material/Autocomplete';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import instance from '../../utils/axios';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField } from '@mui/material';
import { openSnackBar } from '../../redux/actions/snackbar.actions';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { apiErrorHandler } from '../../utils/errorHandler';
import { getApiUrl } from '../../utils/apiUrls';
import { format } from 'date-fns';
import { getMomentInstance } from '../../utils/moments'
import CircularProgress from '@mui/material/CircularProgress';
import AnimationDataLoader from '../../components/Loaders/animationData';
import { v4 as uuid } from 'uuid';
import useDebounce from '../../hooks/useDebounce';
import "./CreateOrEditAllTask.styles.css"


import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { cssClass } from 'brace/theme/monokai';
import { ChevronLeft } from '@mui/icons-material';
import { faArrowUpRightAndArrowDownLeftFromCenter } from '@fortawesome/pro-thin-svg-icons';

const levelOptions = [
    { label: 'Easy', value: 'Easy' },
    { label: 'Medium', value: 'Medium' },
    { label: 'Difficult', value: 'Difficult' },
];

function CreateOrEditAllTaskModalContainer({ modalStatus, handleModalToggle, taskCreated, handleTaskStatus, actionType, taskData }) {

    const [formVal, setFormVal] = React.useState({
        requestFor: "", skill: "", skillId: "",
        topicName: "", questionCount: '', difficultyLevel: "Easy", assignedTo: '', assignedName: "", assignedEmail: "", targetDate: "",
        subSkills: []
    })
    const [value, setValue] = React.useState("");
    const { t } = useTranslation();
    const currentDate = new Date();
    const [subSkillValue, setSubSkillValue] = React.useState({
        typeOf: "Type1",
        subskillName: "",
        questionType: "MCQ",
        questionLevel: "Application",
        difficulty: "Easy",
        experienceLevel: "0-2 years",
        jobRole: "Job1",
        questionCount: ""
    })

    var vendorList = [];
    const dispatch = useDispatch();

    const [allUsers, setAllUsers] = React.useState([]);

    const [targetDate, setTargetDate] = React.useState();
    const [inputValue, setInputValue] = React.useState('');
    const [errorTopic, setErrorMsgTopic] = React.useState("");
    const [errorMsg, setErrorMsg] = React.useState(false);
    const [taskCreate, setTaskCreate] = React.useState(false);
    const [createOrEditInProgress, setCreateOrEditInProgress] = React.useState(false);
    const [errorObj, setErrorObj] = React.useState({
        difficultyLevel: { status: false, msg: null },
        questionCount: { status: false, msg: null },
        assignedTo: { status: false, msg: null },
        targetDate: { status: false, msg: null },
        requestFor: { status: false, msg: null },
        subSkills: { status: false, msg: null },
        skill: { status: false, msg: null },
        countMatching: { status: false, msg: null },
        topicName: { status: false, msg: null },
        subSkillNames: { status: false, msg: null },
        SubSkillmatching: { status: false, msg: null },
    })
    const [taskId, setTaskId] = React.useState(null);
    const [subskillCountError, setSubskillCountError] = React.useState(false);
    const [subskillNameError, setSubskillNameError] = React.useState(false);

    const [allSkills, setAllSkills] = React.useState([]);
    const [allSubSkills, setAllSubSkills] = React.useState([]);
    const [tempSubSkills, setTempSubSKills] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false)
    const [selectedsubskillName, setselectedsubskillName] = React.useState('');
    const [selectedSkill, setSelectedskill] = React.useState('')
    const [matchedSubSKills, setmatchedSubSKills] = React.useState([])

    // React.useEffect(() => {
    //     if (actionType === 'edit') {
    //         setFormVal({
    //             topicName: taskData.topicName, questionCount: taskData.questionCount, difficultyLevel: taskData.difficultyLevel, assignedTo: taskData.assignedTo, assignedName: taskData.assignedName, assignedEmail: taskData.assignedEmail, targetDate: taskData.targetDate
    //         });
    //         setValue(getMomentInstance(taskData.targetDate));
    //     }
    // }, [])


    const [skillSearch, setSkillSearch] = React.useState('')
    const [SmeNameSearch, setSmeNameSearch] = React.useState('')

    useDebounce(() => {
        if (selectedsubskillName.length >= 3) {
           if(selectedSkill) handleSubSkills(selectedSkill);
        }
    }, [selectedsubskillName, 500])

    useDebounce(() => {
        if (SmeNameSearch.length >= 3) addReviewers();
        else if (SmeNameSearch.length < 3) setAllUsers([]);
    }, [SmeNameSearch], 500)

    useDebounce(() => {
        if (skillSearch.length >= 3) handleSkills();
        else if (skillSearch.length < 3) setAllSkills([]);
    }, [skillSearch], 500);

    const handleSkills = async () => {
        try {
            setIsLoading(true);
            const response = await instance.get(`${getApiUrl('getAllSkills')}?skill=${skillSearch}`);
            const skillData = response.data?.map((element, index) => {
                return { label: element.name, value: element.name, id: element.id }
            })
            setAllSkills(skillData)
            handleEditSKills(skillData);
        } catch (err) {
            console.log("Error occured while fetching all skills");
            const errObj = apiErrorHandler(err);
            dispatch(openSnackBar({
                msg: t(errObj.statusText),
                type: 'error'
            }))
        }
        setIsLoading(false);
    }

    const handleEditSKills = (allSkills) => {
        allSkills?.forEach(element => {
            if (element.value === taskData?.topicName && actionType === 'edit') {
                if (taskData.taskType === "KNOWLEDGE BASED QUESTIONS") {
                    setSelectedskill(element.id)
                    handleSubSkills(element.id);
                }
            }
        })
    }



    React.useEffect(() => {
        let prevskillId = -1;

        if (actionType === 'edit') {
            handleSkills();
            setFormVal({
                requestFor: taskData.taskType, skill: taskData.taskType === "KNOWLEDGE BASED QUESTIONS" ? taskData.topicName : "", skillId: prevskillId != -1 ? prevskillId : '',
                topicName: taskData.taskType === "Codelysis" ? taskData.topicName : "", questionCount: taskData.questionCount, difficultyLevel: taskData.difficultyLevel, assignedTo: taskData.assignedTo, assignedName: taskData.assignedName, assignedEmail: taskData?.assignedEmail, targetDate: taskData.targetDate,
                subSkills: taskData.subSkills !== null && taskData.subSkills !== undefined && taskData.subSkills.length !== 0 ? taskData.subSkills.map(skill => {
                    return {
                        ...skill
                    }
                }) : []
            });
            setValue(getMomentInstance(taskData.targetDate));
        } else {
            setFormVal({
                requestFor: "", skill: "",
                topicName: "", questionCount: '', difficultyLevel: "Easy", assignedTo: '', assignedName: "", assignedEmail: "", targetDate: "",
                subSkills: [subSkillValue]
            })
            setValue("");
        }

    }, [modalStatus])


    React.useEffect(() => {
        setAllUsers([]);
        setSkillSearch('')
        setSmeNameSearch('')
        setAllSkills([])
    }, [modalStatus])



    const handleSubSkillAdd = () => {
        setSubskillCountError(false);
        setSubskillNameError(false);

        let isQuestionCount = true;
        let subSkillNameSelected = true;
        formVal.subSkills.forEach(element => {
            if (element.questionCount === 0 || element.questionCount <= 0 || element.questionCount === '') {
                isQuestionCount = false;
            }
            if (element.subskillName === '') {
                subSkillNameSelected = false;
            }
        });
        if (!subSkillNameSelected) {
            setSubskillNameError(true);

        } else if (!isQuestionCount) {
            setSubskillCountError(true);
        }
        else {
            setFormVal({
                ...formVal,
                subSkills: [...formVal.subSkills, subSkillValue]
            })
            setselectedsubskillName('');
            setTempSubSKills(allSubSkills);
        }
    }

    const handleFieldChange = (e, fieldName) => {
        setFormVal({
            ...formVal,
            [fieldName]: e.target.value,
        })

    }

    const handleSkillSelected = (e, fieldName, reason) => {
        if (reason === 'clear') {
            setFormVal({
                ...formVal,
                [fieldName]: ''
            })
        }
        else {
            setFormVal({
                ...formVal,
                [fieldName]: e.value
            })
        }

        handleData();
        if (fieldName === 'skill' && reason != 'clear') {
            const skillSelected = allSkills.find(item => item.value === e.value);
            setSelectedskill(skillSelected.id)
            handleSubSkills(skillSelected.id);
        }
    }
    const handleSelectChange = (e, fieldName) => {
        setFormVal({
            ...formVal,
            [fieldName]: e.target.value
        })
        handleData();
    }

    const handleSubSkills = async (primarySkillId) => {
        const skillId = parseInt(primarySkillId, 10);
        try {
            const response = await instance.get(`${getApiUrl('getAllSubSkills')}/${skillId}${selectedsubskillName ? `?skillName=${selectedsubskillName}` : ''}`);
            const subSkillsData = response.data?.map((element, index) => {
                return { label: element.name, value: element.name, id: element.id }
            })
            if (!selectedsubskillName) setAllSubSkills(subSkillsData);
            setTempSubSKills(subSkillsData);
        } catch (err) {
            console.log("Error occured while fetching the all sub skills for skill id ", skillId);
            const errObj = apiErrorHandler(err);
            dispatch(openSnackBar({
                msg: t(errObj.statusText),
                type: 'error'
            }))
        }
    }
    const handleSubSkillChange = (e, fieldName, reason, index) => {
        // if (fieldName === 'subskillName') {
        //     if (reason === 'clear') {
        //         const updatedList = formVal.subSkills.map((object, ind) => {
        //             if (ind === index) {
        //                 return { ...object, [fieldName]: '' };
        //             }
        //             return object;
        //         });
        //         setselectedsubskillName('');
        //         setTempSubSKills(allSubSkills);
        //         setFormVal({
        //             ...formVal,
        //             subSkills: updatedList
        //         })
        //     }
        //     else {
        //         const updatedList = formVal.subSkills.map((object, ind) => {
        //             if (ind === index) {
        //                 return { ...object, [fieldName]: e.value };
        //             }
        //             return object;
        //         });

        //         setFormVal({
        //             ...formVal,
        //             subSkills: updatedList
        //         })
        //     }
        // }
        // else {
        //     const updatedList = formVal.subSkills.map((object, ind) => {
        //         if (ind === index) {
        //             return { ...object, [fieldName]: ( fieldName === 'questionCount' ?  parseInt(e.target.value, 10) : e.target.value ) };
        //         }
        //         return object;
        //     });

        //     setFormVal({
        //         ...formVal,
        //         subSkills: updatedList
        //     })
        // }

        if (fieldName === 'subskillName') {
            if(e.target.value?.length > 70) return;
        }

        const updatedList = formVal.subSkills.map((object, ind) => {
            if (ind === index) {
                return { ...object, [fieldName]: ( fieldName === 'questionCount' ?  parseInt(e.target.value, 10) : e.target.value ) };
            }
            return object;
        });

        setFormVal({
            ...formVal,
            subSkills: updatedList
        })
    }
    const handleData = () => {

        for (var i = 0; i < allUsers.length; i++) {
            if (allUsers[i].userId === formVal.assignedTo) {
                setFormVal({
                    ...formVal,
                    ['assignedEmail']: allUsers[i].emailId
                })
                setFormVal({
                    ...formVal,
                    ['assignedName']: allUsers[i].assignedName
                })
                setFormVal({
                    ...formVal,
                    ['assignedEmail']: allUsers[i].assignedEmail
                })
            }
        }
    }

    const addReviewers = async () => {
        try {
            setIsLoading(true);
            const response = await instance.get(`${getApiUrl('getAllUsers')}?role=SME&smeName=${SmeNameSearch}`);
            const aUsers = [];
            // aUsers.push({ label: "open Ai", value: 0, emailId: "" })
            if (response.data) {
                for (var i = 0; i < response.data.length; i++) {
                    aUsers.push({ label: response.data[i].firstName + " " + response.data[i].lastName + ` (${response.data[i].emailId})`, value: response.data[i].userId, emailId: response.data[i].emailId })
                }
            }
            setAllUsers(aUsers);
            setIsLoading(false);
        }
        catch (error) {
            const errObj = apiErrorHandler(error);
            dispatch(openSnackBar({
                msg: t(errObj.statusText),
                type: 'error'
            }))
        }
    }

    const handleSubSkillDelete = (id) => {
        setFormVal({
            ...formVal,
            subSkills: formVal.subSkills.filter((skill, index) => index !== id)
        })
    }

    const createOrEditTask = (e) => {
        e.preventDefault();
        const createOrEditTaskData =
        {
            taskType: formVal.requestFor,
            topicName: formVal.requestFor === "KNOWLEDGE BASED QUESTIONS" ? formVal.skill : formVal.topicName,
            questionCount: formVal.questionCount,
            assignedTo: formVal.assignedTo,
            subSkills: formVal.subSkills,
            difficultyLevel: formVal.difficultyLevel,
            assignedName: "",
            assignedEmail: "",
            targetDate: formVal.targetDate,
        }


        for (var i = 0; i < allUsers.length; i++) {
            if (allUsers[i].value === Number(formVal.assignedTo)) {
                createOrEditTaskData.assignedName = allUsers[i].label;
                createOrEditTaskData.assignedEmail = allUsers[i].emailId;
            }
        }
        handleValidations(createOrEditTaskData);
    }



    const handleDtChange = (newValue) => {
        setValue(newValue);
        setFormVal({
            ...formVal,
            ['targetDate']: Date.parse(newValue._d)
        })
    }

   
    function compareSubKills(object1, object2) {
        const keys1 = Object.keys(object1);
        const keys2 = Object.keys(object2);
        for (let key of keys1) {
            if (object1[key] !== object2[key]) {
                return false;
            }
        }
        return true;
    }


    const handleValidations = async (createOrEditTaskData) => {
        var totalErrorObj = {}
        var error = false;

        if (formVal.requestFor === "") {
            const validObj = { status: true, msg: "Request for cannot be empty" }
            totalErrorObj.requestFor = validObj;
            error = true;
        }
        if (formVal.requestFor === "Codelysis" && formVal.topicName === "") {
            const validObj = { status: true, msg: "Topic cannot be empty" }
            totalErrorObj.topicName = validObj;
            error = true;
        }
        if (formVal.questionCount === -1 || formVal.questionCount === "" || formVal.questionCount <= 0) {
            const validObj = { status: true, msg: "Question Count invalid" }
            totalErrorObj.questionCount = validObj;
            error = true;
            //setErrorObj({...errorObj,questionCount:validObj});
        }
        if (formVal.assignedTo === '') {
            const validObj = { status: true, msg: "User name cannot be empty" }
            totalErrorObj.assignedTo = validObj;
            error = true;
            //setErrorObj({...errorObj,assignedTo:validObj});
        }
        if (formVal.targetDate === "") {
            const validObj = { status: true, msg: "Target Date cannot be empty" }
            totalErrorObj.targetDate = validObj;
            error = true;
            //setErrorObj({...errorObj,assignedTo:validObj});
        }
        if (formVal.requestFor === "KNOWLEDGE BASED QUESTIONS" && formVal.subSkills.length === 0) {
            // totalErrorObj.difficultyLevel = {status: false, msg: ""};
            const validObj = { status: true, msg: "Sub-skills cannot be empty" }
            totalErrorObj.subSkills = validObj;
            error = true;
        }
        if (formVal.requestFor === "KNOWLEDGE BASED QUESTIONS" && (formVal.skill === "" || formVal.skill.trim() === "")) {
            const validObj = { status: true, msg: "Skill cannot be empty" }
            totalErrorObj.skill = validObj;
            error = true;
        }
        if (formVal.difficultyLevel === "" && formVal.requestFor === "Codelysis") {
            // totalErrorObj.subSkills = {status: false, msg: ""};
            const validObj = { status: true, msg: "Choose a difficulty level." }
            totalErrorObj.difficultyLevel = validObj;
            error = true;
            //setErrorObj({...errorObj,difficultyLevel:validObj});
        }
        if (formVal.requestFor === "KNOWLEDGE BASED QUESTIONS" && formVal.subSkills.length !== 0) {
            let isSubSkillNames = true;
            for (let subSkill of formVal.subSkills) {
                if (subSkill.subskillName?.length === 0 || subSkill.subskillName.trim() === "") {
                    const validObj = { status: true, msg: "Sub-skill names cannot be empty" }
                    totalErrorObj.subSkillNames = validObj;
                    error = true;
                }
            }
        }
        if (formVal.requestFor === "KNOWLEDGE BASED QUESTIONS" && formVal.subSkills.length !== 0) {
            let totalQuestionCountSum = 0;
            let isQuestionCountValid = true;
            for (let subSkill of formVal.subSkills) {
                if (subSkill.questionCount === '' || subSkill.questionCount === 0) {
                    isQuestionCountValid = false
                    const validObj = { status: true, msg: "Question count value is invalid" }
                    totalErrorObj.countMatching = validObj;
                    error = true;
                }
                totalQuestionCountSum += parseInt(subSkill.questionCount);
            }

            totalQuestionCountSum = parseInt(totalQuestionCountSum, 10);
            let questionCount = parseInt(formVal.questionCount, 10);
            if (totalQuestionCountSum != questionCount && isQuestionCountValid) {
                const validObj = { status: true, msg: "Question counts do not match" }
                totalErrorObj.countMatching = validObj;
                error = true;
            }
        }
        let isSubSkillsSame = true;
        const matchedSubSKills = [];
        for (let i = 0; i < formVal.subSkills.length; i++) {
            if (!(matchedSubSKills.includes(i + 1))) {
                for (let j = i + 1; j < formVal.subSkills.length; j++) {
                    const obj1 = {
                        subskillName: formVal.subSkills[i].subskillName,
                        questionType: formVal.subSkills[i].questionType,
                        questionLevel: formVal.subSkills[i].questionLevel,
                        difficulty: formVal.subSkills[i].difficulty,
                    }
                    const obj2 = {
                        subskillName: formVal.subSkills[j].subskillName,
                        questionType: formVal.subSkills[j].questionType,
                        questionLevel: formVal.subSkills[j].questionLevel,
                        difficulty: formVal.subSkills[j].difficulty,
                    }
                    if (compareSubKills(obj1, obj2)) {
                        if (!(matchedSubSKills.includes(i + 1))) matchedSubSKills.push(i + 1);
                        if (!(matchedSubSKills.includes(j + 1))) matchedSubSKills.push(j + 1)
                        isSubSkillsSame = false
                    }
                }
                if (matchedSubSKills.at(-1) !== -1 && matchedSubSKills.length !== 0) {
                    matchedSubSKills.push(-1);
                }
            }
        }
        if (!isSubSkillsSame) {
            if (matchedSubSKills.at(-1) === -1) matchedSubSKills.pop();
            const formattedValues = matchedSubSKills.map(value => {
                if (value === -1) {
                  return '&';
                }
                return value.toString();
              });
            const displayValues = formattedValues.join(',');
          let  displayValue=displayValues.replace(',&,', ' & ');
            const validObj = { status: true, msg: displayValue }
            totalErrorObj.SubSkillmatching = validObj;
            error = true;
        }

        setErrorObj(totalErrorObj);
        if (!error) {
            setCreateOrEditInProgress(true);
            if (actionType === 'create') {
                try {
                    const response = await instance.post(getApiUrl('createTask'), createOrEditTaskData);
                    if (response.data) {
                        setTaskCreate(true);
                        setTaskId(response.data.id);
                        dispatch(openSnackBar({
                            msg: "New request is created for this SME. Check SME Dasboard for details.",
                            type: 'success'
                        }))
                        handleTaskStatus();
                        handleModalToggle();
                    }
                }
                catch (error) {
                    const errObj = apiErrorHandler(error);
                    dispatch(openSnackBar({
                        msg: t(errObj.statusText),
                        type: 'error'
                    }))
                }
                finally {
                    setCreateOrEditInProgress(false);
                }


                setFormVal({
                    requestFor: "", skill: "",
                    topicName: "", questionCount: '', difficultyLevel: "Easy", assignedTo: '', assignedName: "", assignedEmail: "", targetDate: "",
                    subSkills: []
                })
                setValue("");

            }
            else if (actionType === 'edit') {
                try {
                    const response = await instance.post(getApiUrl('editTask') + "?taskId=" + taskData.taskId, createOrEditTaskData);
                    if (response.data) {
                        setTaskCreate(true);
                        setTaskId(response.data.id);
                        dispatch(openSnackBar({
                            msg: "Request has been successfully updated. Check SME Dasboard for details.",
                            type: 'success'
                        }))
                        handleTaskStatus();
                        handleModalToggle();
                    }
                }
                catch (error) {
                    const errObj = apiErrorHandler(error);
                    dispatch(openSnackBar({
                        msg: t(errObj.statusText),
                        type: 'error'
                    }))
                }
                finally {
                    setCreateOrEditInProgress(false);
                }
            }
        }
    }
    const handleInputChange = (newInputValue, fieldName,index=-1) => {
        if (newInputValue.length >= 3) setIsLoading(true)
        if (fieldName === 'skillSearch') {
            setSkillSearch(newInputValue);
        }
        else if (fieldName === 'SmeNameSearch') {
            setSmeNameSearch(newInputValue)
        }
        else {
            setselectedsubskillName(newInputValue)
            const updatedList = formVal.subSkills.map((object, ind) => {
                if (ind === index) {
                    return { ...object, [fieldName]: newInputValue };
                }
                return object;
            });

            setFormVal({
                ...formVal,
                subSkills: updatedList
            })
        }
    }



    const tableContainerStyle = { boxShadow: "none", borderWidth: "1px", borderBottomLeftRadius: "0", borderBottomRightRadius: "0", borderBottom: "none", borderColor: "blue-gray-400" }
    const tableSubSkillStyle = { width: "50%", fontSize: "12px", padding: "0px 4px" }
    const tableTypeStyle = { width: "10%", fontSize: "12px", padding: "0px 4px" };
    const tableLevelStyle = { width: "10%", fontSize: "12px", padding: "0px 4px" };
    const tableDifficultyStyle = { width: "10%", fontSize: "12px", padding: "0px 4px" };
    const tableExperienceStyle = { width: "10%", fontSize: "12px", padding: "0px 4px" };
    const tableQuestionCountStyle = { width: "8%", fontSize: "12px", padding: "0px 4px" };
    const tableActionTypeStyle = { width: "5%", fontSize: "12px", padding: "0px 4px" };
    const tableCellStyle = {padding: "6px 4px" };
   

    const loadSelectMenuItem = (list) => list.map(el => <MenuItem value={el.value} key={el.value}>{el.label}</MenuItem>)

    const requestOptions = [{ label: "Knowledge Based Questions (MCQ/MCA and Long Answer)", value: "KNOWLEDGE BASED QUESTIONS" }, { label: "Codelysis", value: "Codelysis" }];
    const loadSelectOptions = (list) => list.map(el => <MenuItem value={el.value} key={el.value}>{el.label}</MenuItem>)

    const subSkillCategoryOptions = [{ label: "Application", value: "Application" }, { label: "Analysis", value: "Analysis" }, { label: "Concept", value: "Concept" }, { label: "Knowledge", value: "Knowledge" }];
    const typeOptions = [{ label: "Type1", value: "Type1" }, { label: "Type2", value: "Type2" }, { label: "Type3", value: "Type3" }, { label: "Type4", value: "Type4" }];
    const questionTypeOptions = [{ label: "MCQ", value: "MCQ" }, { label: "MCA", value: "MCA" }, { label: "Long Answer", value: "Long Answer" }]
    const jobOptions = [{ label: "Job1", value: "Job1" }, { label: "Job2", value: "Job2" }]
    const experienceOptions = [{ label: "0-2 years", value: "0-2 years" }, { label: "2-5 years", value: "2-5 years" }, { label: "5+ years", value: "5+ years" }]

    return (
        <div>
            <Modal open={modalStatus} onClose={handleModalToggle} disableAutoFocus={true}>
                <div className='cm-create-task-form-container bg-white my-3'>
                    <div className='cm-create-task-form-header py-3 px-4 d-flex align-items-center justify-content-between'>
                        {actionType === "create" && <h5 className='text-blue-800 my-0'>Create Request</h5>}
                        {actionType === "edit" && <h5 className='text-blue-800 my-0'>Edit Request</h5>}
                        <FontAwesomeIcon icon={regular("times")} className="p-2 cm-pointer" onClick={handleModalToggle} />
                    </div>
                    {true ?
                        <form onSubmit={createOrEditTask} className="cm-create-task-form px-4 pb-4">
                            <div className='row'>
                                <div className="col-lg-6">
                                    <div className="form-group mb-3">
                                        <label htmlFor="lastName" className='pb-2 text-blue-gray-700 cm-xs-txt fw-medium'>Request for</label>
                                        <Select
                                            id="request-select"
                                            value={formVal.requestFor}
                                            onChange={val => handleSelectChange(val, 'requestFor')}
                                        >
                                            {loadSelectOptions(requestOptions)}
                                        </Select>
                                        {errorObj.requestFor?.status && <span className='cm-xs-txt text-danger fw-medium pt-2'>{errorObj.requestFor.msg}</span>}
                                    </div>
                                </div>

                                {/* <div className="col-lg-6"> */}
                                {formVal.requestFor && (formVal.requestFor === "Codelysis"
                                    ?
                                    <div className="col-lg-6">
                                        <div className="form-group mb-3">
                                            <label htmlFor="lastName" className='pb-2 text-blue-gray-700 cm-xs-txt fw-medium'>Topic</label>
                                            <input type="text" className="cm-inp text-blue-800 fw-medium" name="lastName" value={formVal.topicName} placeholder={"Enter Topic "} onChange={(e) => handleFieldChange(e, 'topicName')} />
                                            {errorObj.topicName?.status && <span className='cm-xs-txt text-danger fw-medium pt-2'>{errorObj.topicName.msg}</span>}
                                        </div>
                                    </div>
                                    :
                                    <div className="col-lg-6">
                                        <div className="form-group mb-3">
                                            <label htmlFor="lastName" className='pb-2 text-blue-gray-700 cm-xs-txt fw-medium'>Skill</label>
                                            {/* <Autocomplete
                                                id="skill-select"
                                                value={formVal.skill ? { label: formVal.skill, value: formVal.skill, id: formVal.skillId } : { label: skillSearch, value: skillSearch, id: '' }}
                                                isOptionEqualToValue={(option, value) => option.id === (value.id)}
                                                noOptionsText={(skillSearch.length >= 3) ? (isLoading ? 'Loading...' : "No Options") : 'Atleast 3 Characters should be there to search'}
                                                filterOptions={(options, state) => options}
                                                onInputChange={(event, newInputValue) => { handleInputChange(newInputValue, 'skillSearch') }}
                                                options={allSkills}
                                                onChange={(val, newValue, reason) => handleSkillSelected(newValue, 'skill', reason)}
                                                renderInput={(params) => <TextField
                                                    {...params}

                                                />}
                                            /> */}
                                            <input type="text" className="cm-inp text-blue-800 fw-medium" name="lastName" value={formVal.skill} placeholder={"Enter Skill "} onChange={(e) => handleFieldChange(e, 'skill')} />
                                            {errorObj.skill?.status && <span className='cm-xs-txt text-danger fw-medium pt-2'>{errorObj.skill.msg}</span>}
                                        </div>
                                    </div>)
                                }

                            </div>
                            {/* </div> */}

                            {formVal.requestFor && <div className='row'>
                                <div className="col-lg-6">
                                    <div className="form-group mb-3">
                                        <label htmlFor="firstName" className='pb-2 text-blue-gray-700 cm-xs-txt fw-medium'>Question Count</label>
                                        <input type="number" min="1" className="cm-inp text-blue-800 fw-medium" id="firstName" value={formVal.questionCount} placeholder={"Enter Question Count"} onChange={(e) => handleFieldChange(e, 'questionCount')} />
                                        {errorObj.questionCount?.status && <span className='cm-xs-txt text-danger fw-medium pt-2'>{errorObj.questionCount.msg}</span>}

                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group mb-3">
                                        <label htmlFor="lastName" className='pb-2 text-blue-gray-700 cm-xs-txt fw-medium'>SME/Vendor</label>
                                        <Autocomplete
                                            id="vendor-select"
                                            value={formVal.assignedName ? { label: formVal.assignedName, value: formVal.assignedName, id: formVal.assignedTo } : { label: SmeNameSearch, value: SmeNameSearch, id: '' }}
                                            isOptionEqualToValue={(option, value) => option.id === (value.id)}
                                            noOptionsText={(SmeNameSearch.length >= 3) ? (isLoading ? 'Loading...' : "No Options") : 'Atleast 3 Characters should be there to search'}
                                            filterOptions={(options, state) => options}
                                            onInputChange={(event, newInputValue) => { handleInputChange(newInputValue, 'SmeNameSearch') }}
                                            options={allUsers}
                                            onChange={(val, newValue, reason) => handleSkillSelected(newValue, 'assignedTo', reason)}
                                            renderInput={(params) => <TextField
                                                {...params}
                                            />
                                            }
                                        />
                                        {errorObj.assignedTo?.status && <span className='cm-xs-txt text-danger fw-medium pt-2'>{errorObj.assignedTo.msg}</span>}

                                    </div>
                                </div>
                            </div>
                            }


                            {formVal.requestFor === "Codelysis" &&
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="form-group mb-3">
                                            <label htmlFor="lastName" className='pb-2 text-blue-gray-700 cm-xs-txt fw-medium'>Level</label>
                                            <Select
                                                id="level-select"
                                                value={formVal.difficultyLevel}
                                                onChange={val => handleSelectChange(val, 'difficultyLevel')}>
                                                {loadSelectMenuItem(levelOptions)}
                                            </Select>
                                            {errorObj.difficultyLevel?.status && <span className='cm-xs-txt text-danger fw-medium pt-2'>{errorObj.difficultyLevel.msg}</span>}
                                        </div>
                                    </div>
                                </div>
                            }


                            {formVal.requestFor === "KNOWLEDGE BASED QUESTIONS" && formVal.skill && formVal.skill.trim() &&
                                <div style={{ width: "100%", marginBottom: "1rem" }}>

                                    <label htmlFor="lastName" className='pb-2 text-blue-gray-700 cm-xs-txt fw-medium'>Sub-skills</label>

                                    <TableContainer className='table-container ' sx={tableContainerStyle} component={Paper}>
                                        <Table sx={{ minWidth: "50%" }} aria-label="simple table">
                                            <TableHead >
                                                <TableRow className="cm-event-subskills-table"  >
                                                    {/* <TableCell align='justify' sx={tableHeaderStyle}>Type Of</TableCell> */}
                                                    <TableCell align='center' sx={tableSubSkillStyle}>Name</TableCell>
                                                    <TableCell align='center' sx={tableTypeStyle}>Type</TableCell>
                                                    <TableCell align='center' sx={tableLevelStyle}>Levels</TableCell>
                                                    <TableCell align='center' sx={tableDifficultyStyle}>Difficulty</TableCell>
                                                    <TableCell align='center' sx={tableExperienceStyle}>Experience</TableCell>
                                                    {/* <TableCell align='justify' sx={tableHeaderStyle}>Job Role</TableCell> */}
                                                    <TableCell align='center' sx={tableQuestionCountStyle}>Question Count</TableCell>
                                                    <TableCell align='center' sx={tableActionTypeStyle} >{
                                                        <div className='subskill-add-button'>
                                                            <span onClick={handleSubSkillAdd} className="text-action-blue fw-medium cm-pointer subskill-add-button-icon" >
                                                                <strong>+</strong>
                                                            </span>
                                                        </div>
                                                    }
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {formVal?.subSkills.map((currSkill, index) => {
                                                    return (
                                                        <TableRow key={index}>
                                                            {/* SubSkillName */}
                                                            <TableCell align='center' sx={tableCellStyle} className='my-0  text-blue-800' component="th" scope="row" >{
                                                                <div className='subskill-input-tabelcell'>
                                                                    <div className="form-group">
                                                                        {/* <Autocomplete
                                                                            id="name-select"
                                                                            value={{ label: currSkill.subskillName, value: currSkill.subskillName, id: index }}
                                                                            isOptionEqualToValue={(option, value) => option.id === (value.id)}
                                                                            noOptionsText={'No Options'}
                                                                            filterOptions={(options, state) => options}
                                                                            onInputChange={(event, newInputValue) => {handleInputChange(newInputValue, 'subskillName',index) }}
                                                                            options={tempSubSkills}
                                                                            onChange={(val, newValue, reason) => handleSubSkillChange(newValue, 'subskillName', reason, index)}
                                                                            renderInput={(params) => <TextField
                                                                                {...params}
                                                                            />
                                                                            }
                                                                        /> */}
                                                                        <input 
                                                                        type="text" 
                                                                        name="subSkill" 
                                                                        className="cm-inp text-blue-800 fw-medium" 
                                                                        value={currSkill.subskillName} 
                                                                        placeholder={"Enter Sub Skill"} 
                                                                        onChange={(e) => handleSubSkillChange(e, 'subskillName', 'none', index)}
                                                                      />
                                                                    </div>
                                                                </div>}
                                                            </TableCell>

                                                            {/* Question Type mca mcq */}
                                                            <TableCell align='center' sx={tableCellStyle} className='my-0  text-blue-800' component="th" scope="row">  {<div className='subskill-input-tabelcell'>
                                                                <div className="form-group">
                                                                    <Select
                                                                        id="question-type-select"
                                                                        value={currSkill.questionType}
                                                                        onChange={val => handleSubSkillChange(val, 'questionType', 'none', index)}>
                                                                        {loadSelectMenuItem(questionTypeOptions)}
                                                                    </Select>
                                                                </div>
                                                            </div>}
                                                            </TableCell>

                                                            {/* Question Levels application knowledge etc */}
                                                            <TableCell align='center' sx={tableCellStyle}  className='my-0  text-blue-800' component="th" scope="row"> {<div className='subskill-input-tabelcell'>
                                                                <div className="form-group">
                                                                    <Select
                                                                        id="question-level-select"
                                                                        value={currSkill.questionLevel}
                                                                        onChange={val => handleSubSkillChange(val, 'questionLevel', 'none', index)}>
                                                                        {loadSelectMenuItem(subSkillCategoryOptions)}
                                                                    </Select>
                                                                </div>
                                                            </div>}
                                                            </TableCell>

                                                            {/* Difficulty Levels */}
                                                            <TableCell align='center' sx={tableCellStyle}  className='my-0  text-blue-800' component="th" scope="row" >{<div className='subskill-input-tabelcell'>
                                                                <div className="form-group">
                                                                    <Select
                                                                        id="level-select"
                                                                        value={currSkill.difficulty}
                                                                        onChange={val => handleSubSkillChange(val, 'difficulty', 'none', index)}>
                                                                        {loadSelectMenuItem(levelOptions)}
                                                                    </Select>
                                                                </div>
                                                            </div>}
                                                            </TableCell>

                                                            {/* Experience Levels */}
                                                            <TableCell align='center' sx={tableCellStyle}  className='my-0  text-blue-800' component="th" scope="row"> {<div className='subskill-input-tabelcell'>
                                                                <div className="form-group">
                                                                    <Select
                                                                        id="level-select"
                                                                        value={currSkill.experienceLevel}
                                                                        onChange={val => handleSubSkillChange(val, 'experienceLevel', 'none', index)}>
                                                                        {loadSelectMenuItem(experienceOptions)}
                                                                    </Select>
                                                                </div>
                                                            </div>}
                                                            </TableCell>

                                                            {/* Job Role */}
                                                            {/* <div className='subskill-input-tag'>
                                                                <div className="form-group mb-3">
                                                                    <Select
                                                                        id="level-select"
                                                                        value={subSkillValue.jobRole}
                                                                        onChange={val => handleSubSkillChange(val, 'jobRole')}>
                                                                        {loadSelectMenuItem(jobOptions)}
                                                                    </Select>
                                                                </div>
                                                            </div> */}



                                                            {/* Count */}
                                                            <TableCell align='center' sx={tableCellStyle}  className='my-0  text-blue-800' component="th" scope="row" >  {<div className='subskill-input-tabelcell'>
                                                                <div className="form-group">
                                                                    <input type="number" min="1" className="cm-inp text-blue-800 fw-medium" id="firstName" value={currSkill.questionCount}  onChange={(e) => handleSubSkillChange(e, 'questionCount', 'none', index)} />
                                                                    {/* {errorObj.questionCount?.status && <span className='cm-xs-txt text-danger fw-medium pt-2'>{errorObj.questionCount.msg}</span>} */}

                                                                </div>
                                                            </div>}
                                                            </TableCell>

                                                            <TableCell align='center' sx={tableCellStyle} className='my-0  text-blue-800' component="th" scope="row">
                                                                {<div className='my-0 cm-sm-txt text-blue-800' sx={{ width: "6%" }}><FontAwesomeIcon onClick={() => handleSubSkillDelete(index)} className='text-action-blue cm-sm-txt fw-medium px-3 py-1 cm-pointer' icon={regular("trash")} /> </div>}
                                                            </TableCell>


                                                        </TableRow>)
                                                })
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>

                                    {subskillCountError && <span className='cm-xs-txt text-danger fw-medium pt-2' style={{ marginLeft: "0.2rem" }}>Question count invalid</span>}
                                    {subskillNameError && <span className='cm-xs-txt text-danger fw-medium pt-2' style={{ marginLeft: "0.2rem" }}>SubSkill Name cannot be empty</span>}
                                    {errorObj.subSkills?.status && <span className='cm-xs-txt text-danger fw-medium pt-2'>{errorObj.subSkills.msg}</span>}
                                    {!errorObj.subSkillNames?.status && errorObj.countMatching?.status && <span className='cm-xs-txt text-danger fw-medium pt-2'>{errorObj.countMatching.msg}</span>}
                                    {errorObj.subSkillNames?.status && <span className='cm-xs-txt text-danger fw-medium pt-2'>{errorObj.subSkillNames.msg}</span>}
                                    {!errorObj.subSkillNames?.status && !errorObj.countMatching?.status && errorObj.SubSkillmatching?.status && <span className='cm-xs-txt text-danger fw-medium pt-2'>Duplicate rows found :  rows {errorObj.SubSkillmatching.msg}</span>}
                                </div>
                            }


                            {formVal.requestFor && <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group mb-3">
                                        <label htmlFor="lastName" className='pb-2 text-blue-gray-700 cm-xs-txt fw-medium'>Target Date</label>
                                        <div className=' text-blue-800 fw-medium'>
                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <DatePicker
                                                    value={value}
                                                    onChange={(newValue) => {
                                                        handleDtChange(newValue);
                                                    }} renderInput={(params) => <TextField {...params} />}
                                                    minDate={format(currentDate, 'yyyy-MM-dd')}
                                                />
                                            </LocalizationProvider>
                                        </div>
                                        {errorObj.targetDate?.status && <span className='cm-xs-txt text-danger fw-medium pt-2'>{errorObj.targetDate.msg}</span>}
                                    </div>
                                </div>
                            </div>
                            }
                            {errorMsg && <label className='text-danger fw-small'>{errorTopic}</label>}
                            {formVal.requestFor && <div className="cm-form-btn-group pt-2 d-flex align-items-center">
                                <button onClick={createOrEditTask} className="bg-action-blue fw-medium text-white cm-pointer border border-action-blue" disabled={createOrEditInProgress}>
                                    {actionType === "create" && <span className="text-white">Create Request</span>}
                                    {actionType === "edit" && <span className="text-white">Edit Request</span>}
                                    {!createOrEditInProgress ? <FontAwesomeIcon icon={regular('angle-right')} className="ps-2" /> : <CircularProgress style={{ marginLeft: "5px", height: "15px", width: "15px" }} />}
                                </button>
                                <button onClick={handleModalToggle} className={`ms-3 bg-white fw-medium text-action-blue cm-pointer border border-action-blue`}>
                                    <span>Discard</span>
                                </button>
                            </div>
                            }
                        </form>
                        :
                        <AnimationDataLoader />
                    }
                </div>
            </Modal >
        </div >
    );
}

export default CreateOrEditAllTaskModalContainer