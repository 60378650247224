import React from "react";
import BankDashboardSidebar from "./BankDashboardSidebar";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb.component";
import BankQuestionsDashboard from "./BankQuestionsDashboard";
import { useParams } from "react-router-dom";
import instance from "../../utils/axios";
import { appError } from "../../redux/actions/error.action";
import { useDispatch } from "react-redux";
import { getApiUrl } from "../../utils/apiUrls";
import UploadBankQuestions from "../../pages/upload-questions/UploadBankquestionsModal";
import "./MyBank.css";

const BankDashboard = () => {
    const { bankId } = useParams();
    const dispatch = useDispatch();
    const [breadcrumbData, setBreadcrumbData] = React.useState([
        {
            iconClass: regular("house"),
            link: '/'
        },
        {
            label: "My Bank",
            link: '/my-bank'
        },
    ]);
    const createButton = "Upload"
    const [allSkills, setAllSkills] = React.useState({
        skillData: [],
        permissions: null
    });
    const [questionData, setQuestionData] = React.useState([])
    const [questionCount, setquestionCount] = React.useState(0);
    const [skillId, setSkillId] = React.useState(0);
    const [pageCount, setPageCount] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(20);
    const [bankName, setBankName] = React.useState("");
    const [revieweBankId, setReviewBankId] = React.useState(bankId)
    const [isSKillsLoading, setIsSkillsLoading] = React.useState(true);
    const [uploadQuestionsModalStatus, setUploadQuestionsModalStatus] = React.useState(false);
    const [bankData, setBankData] = React.useState({
        revieweBankId: bankId,
        questionData: null,
        skillId: 0,
        pageCount: 0,
        pageSize: 20,
        pageIndex: 1,
        isLoading: true,
        isBankIdChanged: false,
    })
    const [isBreadcrumadded, setIsBreadcrumadded] = React.useState(false);
    const [fetchBankDetails, setFetchBankDetails] = React.useState(true);
    React.useEffect(() => {
        if (fetchBankDetails) {
            getBankSkills();
            getBanksAllQuestions(bankId, 0);
        }
    }, [fetchBankDetails])

    React.useEffect(() => {
        if (bankName !== '' && !isBreadcrumadded) {
            addBreadCrumbObj(bankName);
            setIsBreadcrumadded(true)
        }
    }, [bankName, setIsBreadcrumadded])
    const addBreadCrumbObj = (bankName) => {
        const newObj = {
            label: bankName
        }
        setBreadcrumbData(prevArray => [...prevArray, newObj])
    }
    const handleuploadquestions = () => {
        setUploadQuestionsModalStatus(!uploadQuestionsModalStatus)
    }


    const getBanksAllQuestions = async (currBankId, pIndex, filterData) => {
        setBankData({
            ...bankData,
            pageIndex: pIndex + 1,
            isLoading: true,
            isBankIdChanged: currBankId !== bankId ? true : false,
        })
        const dataToSend = {
            searchField: filterData?.searchField?.text ? { text: filterData?.searchField?.text, type: filterData?.searchField?.type?.value } : null,
            difficultyLevels: filterData?.diffcultyLevel ? filterData.diffcultyLevel?.map(obj => obj.value.toString()) : ['easy', 'medium', 'difficult'],
            flags: filterData?.flagStatus ? filterData.flagStatus.value : 'all',
            reviewStatus: filterData?.reviewStatus ? filterData.reviewStatus.value : "all"
        }
        try {

            const res = await instance.post(`${getApiUrl('getAllQuestionsFromBank')}?bankId=${currBankId}&offset=${pIndex}&pageSize=${pageSize}`, dataToSend)
            if (res.data) {
                setBankData({
                    ...bankData,
                    pageIndex: pIndex + 1,
                    revieweBankId: currBankId,
                    pageCount: (Math.ceil(res.data.totalQuestions / pageSize)),
                    questionData: res.data.reviewQuestionDtos,
                    pageSize: res.data.reviewQuestionDtos.length,
                    isLoading: false,
                    isBankIdChanged: false
                })
            }
            setFetchBankDetails(false);
        }
        catch (error) {
            console.log(error);
            appError('', error, dispatch);
        }
    }
    const getBankNameFromAllSKills = (allSkillsData) => {
        allSkillsData?.forEach((element) => {
            if (element.parentReviewBankId === 0) {
                setBankName(element.reviewBankName);
                setquestionCount(element.questionCount)
            }
        })
    }


    const getBankSkills = async () => {
        setIsSkillsLoading(true)
        try {
            const res = await instance.get(`${getApiUrl('getBankAllSkills')}?bankId=${bankId}`)
            if (res.data) {
                setAllSkills({
                    skillData: res.data.reviewBankDTO,
                    permissions: res.data.reviewBankPermissionDTO
                })
                getBankNameFromAllSKills(res.data.reviewBankDTO);
                setIsSkillsLoading(false);
            }
        } catch (error) {
            console.log(error);
            appError('', error, dispatch);
        }
    }

    return (
        <>
            <div>
                <Breadcrumb data={breadcrumbData} isButtonOption={true} buttonName={createButton} handleToggleModal={handleuploadquestions} />
                <div className="cm-create-event-container d-flex flex-wrap">
                    {allSkills.skillData && <BankDashboardSidebar
                        allSkills={allSkills.skillData} handleSkillChange={getBanksAllQuestions} bankId={bankId} bankName={bankName} questionCount={questionCount} isSKillsLoading={isSKillsLoading} handleChangeBankHierarchy={getBankSkills} />}
                    <BankQuestionsDashboard permissions={allSkills.permissions} bankData={bankData} bankId={revieweBankId} skillId={skillId} questionData={questionData} PageCount={pageCount} getAllBankQuestions={getBanksAllQuestions} bankName={bankName} />
                    <UploadBankQuestions uploadQuestionsModalStatus={uploadQuestionsModalStatus} handleModalToggle={handleuploadquestions} bankId={bankId} />
                </div>
            </div>
        </>
    )
}
export default BankDashboard;