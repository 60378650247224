import React, { useEffect, useRef, useState } from "react";
import instance from "../../utils/axios";
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { getApiUrl } from "../../utils/apiUrls";
import { openSnackBar } from "../../redux/actions/snackbar.actions";
import { useDispatch, useSelector } from "react-redux";
import { apiErrorHandler } from "../../utils/errorHandler";
import { useTranslation } from "react-i18next";
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "./create-knowledgeBased-question.styles.css";
import TextEditor from "../../components/text-editor/TextEditor.component";
import McqQuestionCreationSidebar from "../../containers/question-creation/McqQuestionCreationSidebar.container";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb.component";
import { handleCodeChange } from "../../utils/helperFn";
import { MenuItem, Select } from '@mui/material';
import CustomDialog from "../../components/custom-dialog/CustomDialog.component";



function CreateKnowledgeBasedQuestion() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = React.useState(false);
    const [dataObj, setDataObj] = useState({
        questionText: { value: '', msg: null },
        multipleChoiceAnswerDescription: { value: '', msg: null },
        answerDescription: { value: '', msg: null },
        correctChoiceIndex: { value: '', msg: null },
        correctChoiceIndices: { value: '', msg: null },
        maximumAnswerSelection: { value: '', msg: null }
    });
    const [searchParams] = useSearchParams();
    const toolRef = useRef();
    const location = useLocation();
    const [numberOptions, setNumberOptions] = useState(2);
    const [answer, setAnswer] = useState(-1);
    const [optionsData, setOptionsData] = useState(new Map());
    const [questionData, setQuestionData] = useState("");
    const [answerKeyData, setAnswerKeyData] = useState("");
    const [mcaCorrectAnswers, setMcaCorrectAnswers] = useState([]);
    const [maximumAnswerSelected, setMaximumAnswerSelected] = useState(null);
    const questionType = (location.state?.isEdit || location.state?.isBank) ? location.state.questionType : location.state.currQuestionType;
    const { t } = useTranslation();
    const getReqId = searchParams.get('reqId');

    const [allOptions, setAllOptions] = useState([]);

    const [partialGrading, setPartialGrading] = useState(false);

    let optionsError = [];

    let sharedSpacesConfig = React.useMemo(() => ({ top: 'main-toolbar' }), [])

    const [selectedDifficultyLevel, setSelectedDifficultyLevel] = React.useState((location.state?.isEdit || location.state?.isBank) ? location.state.data?.difficultyLevel : '');

    const [showHasWordLimit, setShowHasWordLimit] = React.useState(false);
    const [enableAutomaticEvaluation, setEnableAutomaticEvaluation] = React.useState(false);
    const [showAdvanceOptions, setShowAdvanceOptions] = React.useState(false);
    const [longAnswerQuestionType, setLongAnswerQuestionType] = React.useState("Essay");
    const [wordLimit, setWordLimit] = React.useState(250);
    const [minimumWordCount, setMinimumWordCount] = React.useState(40);
    const [readability, setReadability] = React.useState(false);
    const [coherence, setCoherence] = React.useState(60.0);
    const [grammar, setGrammar] = React.useState(30.0);
    const [spelling, setSpelling] = React.useState(10.0);
    const [vocabulary, setVocabulary] = React.useState(0.0);

    const [dialogStatus, setDialogStatus] = React.useState(false);

    const user = useSelector((state) => state.user);



    const breadcrumbData = [
        {
            iconClass: regular("house"),
            link: '/'
        },
        {
            label: "Dashboard",
            link: '/'
        }
    ]

    useEffect(() => {
        setAllOptions([
            { choiceText: "", weightage: parseFloat(0.0) },
            { choiceText: "", weightage: parseFloat(0.0) }
        ])
        // console.log("question type",  location.state.allData.questionType);


    }, [])



    const handleQuestDataValidation = (dataToSend) => {
        let req_msg = 'Error: Please provide an input';
        const updatedDataObj = {
            ...dataObj,
        };
        for (const key in dataToSend) {
            if (key === 'questionText') {
                updatedDataObj[key].msg = dataToSend[key] === '' ? req_msg : null
            } else if (key === 'answerDescription') {
                if ((dataToSend.questionType === "MCQ" || dataToSend.questionType === "MCA") && user.role === "ADMIN") {
                    continue;
                }
                else {
                    updatedDataObj[key].msg = dataToSend[key] === '' ? `Error: Please provide a valid ${dataToSend.questionType === "Long Answer" && enableAutomaticEvaluation ? "Reference text" : "Answer Description"}.` : null
                }
            }
            else if (key === 'multipleChoiceAnswerDescription') {
                for (const element in dataToSend[key]) {
                    if (element === 'choices') {
                        for (let index in dataToSend[key][element]) {
                            let choicetxt = dataToSend[key][element];
                            if (choicetxt[index].choiceText === '') {
                                index = parseInt(index, 10) + 1;
                                optionsError.push(index);
                            }
                        }
                        var message = "";
                        if (optionsError.length) {

                            if (optionsError.length === 1) {
                                message = `Error: Answer Choice ${optionsError.map((element) => {
                                    return `${element}`
                                })} can't be blank`
                            }
                            else {
                                message = `Error: Answer Choice's cannot be blank`
                            }

                        } else {
                            message = null;
                        }
                        updatedDataObj[key].msg = message;
                    }
                    else if (dataToSend.questionType === "MCQ" && element === 'correctChoiceIndex') {
                        updatedDataObj[element].msg = (answer === -1) ? "Error: Please select the correct answer choice" : null
                    }
                    else if (dataToSend.questionType === "MCA" && element === 'correctChoiceIndices' && !partialGrading) {
                        updatedDataObj[element].msg = (mcaCorrectAnswers.length <= 0) ? "Error: Please select the  atleast one correct answer choice" : null;
                    }
                    else if (dataToSend.questionType === "MCA" && element === 'maximumAnswerSelection' && partialGrading) {
                        updatedDataObj[element].msg = (maximumAnswerSelected === null) ? "Error: Please select the maximum answer selection" : null;
                    }

                }
            }

        };
        setDataObj(updatedDataObj);
        let isValid = true;
        for (const key in dataObj) {
            if (dataObj.hasOwnProperty(key)) {
                if (dataObj[key].msg !== null) {
                    isValid = false;
                    break;
                }
            }
        }
        if(!isValid){
            dispatch(
                openSnackBar({
                    type: "error",
                    msg: "Please fill all the required data",
                })
            );
        }
        return isValid;
    }

    const saveMcqQuestion = async () => {
        try {
            let dataToSend = {
                questionTitle: location.state.isEdit ? location.state.data.questionTitle : location.state.subskillName, // set accordingly
                topicName: location.state.isEdit ? location.state.data.topicName : location.state.allData.topicName,
                taskId: location.state.taskId,
                difficultyLevel: location.state.isEdit ? location.state.data.difficultyLevel : location.state.currDifficulty,
                questionText: handleCodeChange(questionData, "questionDataId"),
                questionType: questionType,
                isDelete: false,
                multipleChoiceAnswerDescription:
                {
                    choices: handleOptionsChange(allOptions, "choiceText"),
                    partialMarking: partialGrading,
                    correctChoiceIndex: answer,
                    correctChoiceIndices: questionType === "MCA" ? mcaCorrectAnswers : null,
                    maximumAnswerSelection: maximumAnswerSelected
                },
                answerDescription: handleCodeChange(answerKeyData, "answerDescriptionId"),
                creatorStatus: "completed",
                questionLang: "EN"
            };

            const isFormValid = handleQuestDataValidation(dataToSend);
            if (!isFormValid) {
                console.log("Validations Failed ! ", dataObj);
                return;
            }
            let totalWeightage = parseFloat(0.0);
            allOptions.map((element, index) => {
                totalWeightage += element.weightage;
            })
            if (totalWeightage !== parseFloat(100.0) && partialGrading && dataToSend.multipleChoiceAnswerDescription.correctChoiceIndex === -1) {
                dispatch(
                    openSnackBar({
                        type: "error",
                        msg: "Sum of the % marks must be 100%",
                    })
                );
                return;
            }

            setIsLoading(true);
            if (location.state.isEdit) {
                dataToSend = { ...dataToSend, questionId: location.state.data.questionId }
                const res = await instance.post(
                    getApiUrl("updateMultipleChoiceQuestion"),
                    dataToSend
                );
                if (res.data.status) {
                    setIsLoading(false);
                }
                dispatch(
                    openSnackBar({
                        type: res.data.status ? "success" : "error",
                        msg: res.data.message,
                    })
                );
            }
            else {
                const res = await instance.post(
                    getApiUrl("createMultipleChoiceQuestion"),
                    dataToSend
                );
                if (res.data.status) {
                    setIsLoading(false);
                }
                dispatch(
                    openSnackBar({
                        type: res.data.status ? "success" : "error",
                        msg: res.data.message,
                    })
                );
            }




            navigate(`/question-library/${location.state.taskId}`)



        } catch (error) {
            const errObj = apiErrorHandler(error);
            dispatch(
                openSnackBar({
                    msg: t(errObj.statusText),
                    type: "error",
                })
            );
        }
    };

    const handleOptionsChange = (data, key) => {
        let optionsData = [];
        data.map((element, index) => {
            let optiondata = element.choiceText;
            let updatedOptiondata = handleCodeChange(optiondata, `answer-editor-${index}`);
            optionsData.push({
                "choiceText": updatedOptiondata,
                "weightage": element.weightage
            })
        })
        return optionsData;
    }

    const handleOptionChange = (data, currChoiceIndex) => {
        const updatedOptions = [...allOptions];
        updatedOptions[currChoiceIndex].choiceText = data;
        setAllOptions(updatedOptions);

        const newOptionsError = optionsError.filter((element, index) => {
            return element !== currChoiceIndex + 1;
        });

        optionsError = newOptionsError;
    }

    const handleAddOption = () => {
        setAllOptions([
            ...allOptions,
            { choiceText: "", weightage: parseFloat(0.0) }
        ])
        setMaximumAnswerSelected(null);
    }

    const handleDeleteOption = (optionIndex) => {
        if (answer === parseInt(optionIndex, 10) + 1) setAnswer(-1);
        const newOptions = allOptions.filter((element, index) => {
            return optionIndex !== index;
        });
        if (mcaCorrectAnswers.includes(Number(optionIndex) + 1)) {
            const changedMcaAnswer = mcaCorrectAnswers.filter((item => item !== Number(optionIndex) + 1));
            setMcaCorrectAnswers(changedMcaAnswer);
        }
        setMaximumAnswerSelected(null);
        setAllOptions(newOptions);
    }

    const editReviewQuestion = async () => {
        try {
            let dataToSend = {
                reviewQuestionId: location.state.data.reviewQuestionId,
                questionText: handleCodeChange(questionData, "questionDataId"),
                questionType: questionType,
                questionJson:
                {
                    choices: handleOptionsChange(allOptions, "choiceText"),
                    partialMarking: partialGrading,
                    correctChoiceIndex: answer,
                    correctChoiceIndices: mcaCorrectAnswers,
                    maximumAnswerSelection: maximumAnswerSelected
                },
                answerDescription: handleCodeChange(answerKeyData, "answerDescriptionId"),
                questionLanguage: "EN",
                difficultyLevel: selectedDifficultyLevel
            };


            const isFormValid = handleQuestDataValidation(dataToSend);
            if (!isFormValid) {
                return;
            }

            setIsLoading(true);

            let totalWeightage = parseFloat(0.0);
            allOptions.map((element, index) => {
                totalWeightage += element.weightage;
            })
            if (totalWeightage !== parseFloat(100.0) && partialGrading && (dataToSend.questionJson.correctChoiceIndex === -1 || dataToSend.questionJson.correctChoiceIndex == null)) {
                dispatch(
                    openSnackBar({
                        type: "error",
                        msg: "Sum of the % marks must be 100%",
                    })
                );
                return;
            }

            const res = await instance.post(
                getApiUrl("editReviewQuestion"),
                dataToSend
            );

            dispatch(
                openSnackBar({
                    type: res.data.status ? "success" : "error",
                    msg: res.data.message,
                })
            );
            setIsLoading(false);

        } catch (err) {
            setIsLoading(false);
            const errObj = apiErrorHandler(err);
            dispatch(
                openSnackBar({
                    msg: t(errObj.statusText),
                    type: "error",
                })
            );
        }
        navigate(`/my-bank/${location.state.reviewBankId}`)
    }


    useEffect(() => {
    }, [location.state?.data])

    useEffect(() => {
        if (location.state.isBank) {
            setQuestionData(location.state.data.questionText);
            setAnswerKeyData(location.state.data.answerDescription)
            setAnswer(location.state.data.questionJson.correctChoiceIndex)
            setAllOptions(location.state.data.questionJson.choices)
            setPartialGrading(location.state.data.questionJson.partialMarking)
            setMaximumAnswerSelected(location.state.data.questionJson.maximumAnswerSelection)
            setMcaCorrectAnswers(location.state.data.questionJson.correctChoiceIndices)
            setSelectedDifficultyLevel(location.state.data.difficultyLevel)
        }
        else if (location.state.isEdit) {
            setQuestionData(location.state.data.questionText);
            setAnswerKeyData(location.state.data.ansDescription);
            if (questionType === "MCQ" || questionType === "MCA") {
                setAnswer(location.state.data.multipleChoiceAnswerDescription.correctChoiceIndex)
                setAllOptions(location.state.data.multipleChoiceAnswerDescription.choices)
                setPartialGrading(location.state.data.multipleChoiceAnswerDescription.partialMarking)
                setMaximumAnswerSelected(location.state.data.multipleChoiceAnswerDescription.maximumAnswerSelection)
                setMcaCorrectAnswers(location.state.data.multipleChoiceAnswerDescription.correctChoiceIndices)
            }
            else if (questionType === "Long Answer") {
                setShowHasWordLimit(location.state.data.longAnswerDescriptionDTO.hasWordLimit);
                setEnableAutomaticEvaluation(location.state.data.longAnswerDescriptionDTO.isAutoEvaluation);
                setLongAnswerQuestionType(location.state.data.longAnswerDescriptionDTO.longQuestionType);
                setWordLimit(location.state.data.longAnswerDescriptionDTO.wordLimit);
                setMinimumWordCount(location.state.data.longAnswerDescriptionDTO.minWordLimit);
                setReadability(location.state.data.longAnswerDescriptionDTO.readability);
                if (location.state.data.longAnswerDescriptionDTO.coherenceWeightage) {
                    setShowAdvanceOptions(true);
                    setCoherence(location.state.data.longAnswerDescriptionDTO.coherenceWeightage * 100.0);
                    setGrammar(location.state.data.longAnswerDescriptionDTO.grammarWeightage * 100.0);
                    setSpelling(location.state.data.longAnswerDescriptionDTO.spellingWeightage * 100.0);
                    setVocabulary(location.state.data.longAnswerDescriptionDTO.vocabularyWeightage * 100.0);
                }
                else {
                    setShowAdvanceOptions(false);
                    setCoherence(60.0);
                    setGrammar(30.0);
                    setSpelling(10.0);
                    setVocabulary(0.0);
                }
            }

        }

    }, [location.state?.data, location.state?.isEdit])



    const handleBackBtn = () => {
        setDialogStatus(true);
    }

    const setCorrectChoice = (currChoiceIndex) => {
        if (answer !== -1) {
            const updatedOptions = [...allOptions];
            updatedOptions[answer - 1].weightage = parseFloat(0.0);
            setAllOptions(updatedOptions);
        }

        setAnswer(currChoiceIndex + 1);

        const updatedOptions = [...allOptions];
        updatedOptions[currChoiceIndex].weightage = parseFloat(100.0);
        setAllOptions(updatedOptions);
    }

    const handleCheckboxChange = (e) => {
        if (e.target.checked) {
            setAllOptions(allOptions.map((element, index) => {
                return answer - 1 === index ? { ...element, weightage: parseFloat(100.0) } : { ...element, weightage: parseFloat(0.0) }
            }))
        }
        setPartialGrading(e.target.checked);
    }
    const handleMcaAnswerSelection = (e) => {
        if (mcaCorrectAnswers.includes(Number(e.target.value) + 1)) {
            const changedMcaAnswer = mcaCorrectAnswers.filter((item => item !== Number(e.target.value) + 1));
            setMcaCorrectAnswers(changedMcaAnswer);
        }
        else {
            const changedMcaAnswer = [...mcaCorrectAnswers, (Number(e.target.value) + 1)]
            setMcaCorrectAnswers(changedMcaAnswer);
        }
    }
    const handleMaximumAnswerSelection = (e) => {
        setMaximumAnswerSelected(Number(e.target.value));
    }

    const renderOptions = () => {
        let noofoptionsweight = 0;
        allOptions.map((element, index) => {
            if (element.weightage > parseFloat(0.0)) {
                noofoptionsweight += 1;
            }
        })
        const options = []
        options.push(<option value={-1}> </option>)
        for (let i = noofoptionsweight; i <= (Math.ceil((allOptions.length - noofoptionsweight) / 2) + noofoptionsweight); i++) {
            if (i == 0) continue;
            options.push(<option value={i}>{i}</option>)
        }
        return options;
    }


    const handlePartialWeightageChange = (e, currChoiceIndex) => {
        if (parseFloat(e.target.value, 10) > parseFloat(0.0) && mcaCorrectAnswers && !mcaCorrectAnswers?.includes(Number(currChoiceIndex) + 1)) {
            const changedMcaAnswer = [...mcaCorrectAnswers, (Number(currChoiceIndex) + 1)]
            setMcaCorrectAnswers(changedMcaAnswer);
        }
        if (!e.target.value || parseFloat(e.target.value, 10) <= parseFloat(0.0)) {
            if (mcaCorrectAnswers && mcaCorrectAnswers.length && mcaCorrectAnswers.includes(Number(currChoiceIndex) + 1)) {
                const changedMcaAnswer = mcaCorrectAnswers.filter((item => item !== Number(currChoiceIndex) + 1));
                setMcaCorrectAnswers(changedMcaAnswer);
            }
        }
        if (parseFloat(e.target.value, 10) > parseFloat(100.0)) {
            e.target.value = parseFloat(100.0);
        }
        setMaximumAnswerSelected(-1);
        setAllOptions(allOptions.map((element, index) => {
            return index === currChoiceIndex ? { ...element, weightage: parseFloat(e.target.value, 10) } : element;
        }))

    }

    const handleSelectChange = (event) => {
        setSelectedDifficultyLevel(event.target.value);
    }

    const loadSelectOptions = (list) => list.map((el, index) => (
        <MenuItem value={el} key={index}>
            <span className='cm-sm-txt fw-medium  text-blue-gray-700'>{el}</span>
        </MenuItem>
    ));

    const handleLongAnswerSelectChange = (event) => {
        setLongAnswerQuestionType(event.target.value);
    }

    const saveLongAnswerQuestion = async () => {

        try {
            let dataToSend = {
                questionTitle: location.state.isEdit ? location.state.data.questionTitle : location.state.subskillName, // set accordingly
                topicName: location.state.isEdit ? location.state.data.topicName : location.state.allData.topicName,
                taskId: location.state.taskId,
                difficultyLevel: location.state.isEdit ? location.state.data.difficultyLevel : location.state.currDifficulty,
                questionText: handleCodeChange(questionData, "questionDataId"),
                questionType: questionType,
                isDelete: false,
                longAnswerDescriptionDTO:
                {
                    hasWordLimit: showHasWordLimit,
                    wordLimit: wordLimit,
                    marking: enableAutomaticEvaluation ? 2 : 1,
                    minWordLimit: minimumWordCount,
                    longQuestionType: longAnswerQuestionType,
                    readability: readability,
                    vocabularyWeightage: parseFloat(vocabulary) / 100.0,

                },
                answerDescription: handleCodeChange(answerKeyData, "answerDescriptionId"),
                creatorStatus: "completed",
                questionLang: "EN"
            };
            if (enableAutomaticEvaluation) {
                dataToSend = {
                    ...dataToSend,
                    longAnswerDescriptionDTO: {
                        ...dataToSend.longAnswerDescriptionDTO,
                        isAutoEvaluation: enableAutomaticEvaluation,
                        coherenceWeightage: parseFloat(coherence) / 100.0,
                        spellingWeightage: parseFloat(spelling) / 100.0,
                        grammarWeightage: parseFloat(grammar) / 100.0,
                    }
                }

                let totalWeightage = 0.0;

                totalWeightage += parseFloat(coherence);
                totalWeightage += parseFloat(spelling);
                totalWeightage += parseFloat(grammar);
                if (longAnswerQuestionType === "Essay") totalWeightage += parseFloat(vocabulary);
                if (totalWeightage !== 100.0) {
                    dispatch(
                        openSnackBar({
                            type: "error",
                            msg: "Sum of the % marks must be 100%",
                        })
                    );
                    return;
                }
            }
            const isFormValid = handleQuestDataValidation(dataToSend);
            if (!isFormValid) {
                return;
            }

            setIsLoading(true);
            if (location.state.isEdit) {
                dataToSend = { ...dataToSend, questionId: location.state.data.questionId }
                const res = await instance.post(
                    getApiUrl("updateLongAnswerQuestion"),
                    dataToSend
                );
                if (res.data.status) {
                    setIsLoading(false);
                }
                dispatch(
                    openSnackBar({
                        type: res.data.status ? "success" : "error",
                        msg: res.data.message,
                    })
                );
            }
            else {
                const res = await instance.post(
                    getApiUrl("saveLongAnswerQuestion"),
                    dataToSend
                );
                if (res.data.status) {
                    setIsLoading(false);
                }
                dispatch(
                    openSnackBar({
                        type: res.data.status ? "success" : "error",
                        msg: res.data.message,
                    })
                );
            }

            navigate(`/question-library/${location.state.taskId}`);

        } catch (error) {
            const errObj = apiErrorHandler(error);
            dispatch(
                openSnackBar({
                    msg: t(errObj.statusText),
                    type: "error",
                })
            );
        }
    };

    const handleExitCancelFn = () => {
        setDialogStatus(false);
    }

    const handleExitActionFn = () => {
        setDialogStatus(false);
        if (location.state?.isBank) navigate(-1);
        else navigate(`/question-library/${getReqId}`);
    }

    return (
        <>
            <Breadcrumb data={breadcrumbData} />
            <div className="cm-create-event-container d-flex">
                <McqQuestionCreationSidebar stickyTopVal="114px" isLoading={isLoading} t={t} activeStep={0} handleSaveBtn={location.state?.isBank ? editReviewQuestion : (questionType === "Long Answer") ? saveLongAnswerQuestion : saveMcqQuestion} handleBackBtn={handleBackBtn} handleSelectChange={handleSelectChange} selectedDifficultyLevel={selectedDifficultyLevel} />

                <div className="cm-event-library-content-wrapper bg-blue-gray-50 px-5 ms-0">
                    <div id="main-toolbar" style={{ width: "calc(100% + 96px)", marginLeft: "-48px", top: '114px' }}></div>

                    <div className="form-group mb-3 pb-4 questionTextEditor">
                        <label className="pb-2 text-blue-gray-700 cm-xs-txt pb-2 fw-medium">Question Text</label>
                        <TextEditor
                            data={questionData}
                            handeTxtEditorChange={(data) => {
                                setQuestionData(data);
                            }}
                            id="questionDataId"
                            sharedSpacesConfig={sharedSpacesConfig}

                        />
                        {dataObj.questionText.msg !== null && <span className='cm-xs-txt text-danger fw-medium pt-2'>{dataObj.questionText.msg}</span>}
                    </div>

                    {(questionType === "MCQ" || questionType === "MCA") &&
                        <>
                            {questionType === "MCQ" && dataObj.correctChoiceIndex.msg !== null && <span className='cm-xs-txt text-danger fw-medium pt-2'>{dataObj.correctChoiceIndex.msg}</span>}
                            {questionType === "MCA" && !partialGrading && dataObj.correctChoiceIndices.msg !== null && <span className='cm-xs-txt text-danger fw-medium pt-2'>{dataObj.correctChoiceIndices.msg}</span>}

                            {
                                allOptions.length !== 0 && allOptions.map((element, index) => {
                                    return (
                                        <div className="form-group mb-3 d-flex align-items-start" key={index} >
                                            {(location.state?.currQuestionType === "MCQ" || ((location.state?.isBank || location.state?.isEdit) && location.state?.questionType === "MCQ")) ?
                                                <input
                                                    type="radio"
                                                    name="option"
                                                    className="inline-block mx-3 cm-pointer"
                                                    checked={answer === index + 1}
                                                    onChange={() => setCorrectChoice(index)}
                                                />
                                                :
                                                <input
                                                    type="checkbox"
                                                    name="option"
                                                    className={`inline-block mx-3 cm-pointer ${partialGrading ? 'disabledClass' : ''}`}
                                                    value={index}
                                                    disabled={partialGrading ? true : false}
                                                    checked={mcaCorrectAnswers?.includes(index + 1)}
                                                    onChange={(e) => handleMcaAnswerSelection(e)}
                                                />
                                            }
                                            <div className="flex-grow-1 cm-small-editor">
                                                <TextEditor
                                                    data={element.choiceText}
                                                    handeTxtEditorChange={(data) => {
                                                        handleOptionChange(data, index)
                                                    }}

                                                    id={`answer-editor-${index}`}
                                                    placeholder={`Answer Choice ${index + 1}`}
                                                    sharedSpacesConfig={sharedSpacesConfig}
                                                />

                                            </div>
                                            {partialGrading &&
                                                ((!location.state?.isEdit && location.state?.currQuestionType === "MCQ") || ((location.state?.isEdit || location.state?.isBank) && location.state?.questionType === "MCQ")) &&
                                                <div className="partialMarkingBox">
                                                    <div className={answer === index + 1 ? "partialMarkingBoxInput disabledClass" : "partialMarkingBoxInput"} >
                                                        <input type="number" onWheel={(e) => e.target.blur()} placeholder="value" value={`${allOptions[index].weightage}`} disabled={answer === index + 1} className={answer === index + 1 ? "disabledClass" : ""} onChange={(e) => handlePartialWeightageChange(e, index)} />
                                                    </div>
                                                    <span>% marks</span>
                                                </div>
                                            }
                                            {partialGrading &&
                                                ((!location.state?.isEdit && location.state?.currQuestionType === "MCA") || ((location.state?.isEdit || location.state?.isBank) && location.state?.questionType === "MCA")) &&
                                                <div className="partialMarkingBox">
                                                    <div className="partialMarkingBoxInput" >
                                                        <input type="number" onWheel={(e) => e.target.blur()} placeholder="value" value={`${allOptions[index].weightage}`} onChange={(e) => handlePartialWeightageChange(e, index)} />
                                                    </div>
                                                    <span>% marks</span>
                                                </div>
                                            }
                                            <button className="cross-btn px-3 py-2 border-0 bg-transparent" disabled={allOptions.length <= 2}
                                                onClick={() => handleDeleteOption(index)
                                                }><FontAwesomeIcon icon={regular("Xmark")} className="text-blue-gray-700" /></button>
                                        </div>
                                    )
                                })

                            }
                            <div>
                                {dataObj.multipleChoiceAnswerDescription.msg !== null && <span className='cm-xs-txt text-danger fw-medium pt-2'>{dataObj.multipleChoiceAnswerDescription.msg}</span>}
                            </div>

                            <div className="d-flex justify-content-between">
                                <div>
                                    <div className="d-flex mt-3 mx-3 align-items-center">
                                        <input value="test" type="checkbox" checked={partialGrading} onChange={handleCheckboxChange} />
                                        <label className="cm-sm-txt  mx-1 text-blue-gray-700">Award Partial Marks in %</label>
                                    </div>
                                    <span className="d-inline-block mt-3 cm-sm-txt fw-medium ms-2 px-3 py-2 rounded bg-action-blue text-white justify-content-center cm-pointer" onClick={handleAddOption}>Add option</span>
                                </div>
                                {partialGrading && ((!location.state?.isEdit && location.state?.currQuestionType === "MCA") || (location.state?.isEdit && location.state?.questionType === "MCA")) &&
                                    <div className="d-inline-block mt-4 ">
                                        <div>
                                            {partialGrading && ((!location.state?.isEdit && location.state?.currQuestionType === "MCA") || (location.state?.isEdit && location.state?.questionType === "MCA")) && dataObj.maximumAnswerSelection.msg !== null && <span className='cm-xs-txt text-danger fw-medium pt-2'>{dataObj.maximumAnswerSelection.msg}</span>}
                                        </div>
                                        <label className="cm-sm-txt  mx-1 mt-4 text-blue-gray-700">
                                            Maximum answer selection :
                                            <select className="mx-1" value={maximumAnswerSelected} onChange={handleMaximumAnswerSelection}>
                                                {renderOptions()}
                                            </select>
                                        </label>
                                    </div>}
                            </div>
                        </>
                    }

                    {questionType === "Long Answer" &&
                        <>
                            <div className="d-flex justify-content-between border-bottom border-blue-gray-300 mb-1">
                                <div>
                                    <div className='mb-2 d-flex align-items-center user-select-none'>
                                        <input type="checkbox" name={t('has_word_limit')} id={t('has_word_limit')} className="cm-pointer" checked={showHasWordLimit} onChange={(e) => { setShowHasWordLimit(e.target.checked) }} />
                                        <label htmlFor={t('has_word_limit')} className='ps-2 cm-sm-txt text-blue-gray-700 fw-medium'>{t('has_word_limit')}</label>
                                    </div>
                                    <div className='mb-1 pb-3 d-flex align-items-center user-select-none'>
                                        <input type="checkbox" name={t('enable_automatic_evaluation')} id={t('enable_automatic_evaluation')} className="cm-pointer" checked={enableAutomaticEvaluation} onChange={(e) => setEnableAutomaticEvaluation(e.target.checked)} />
                                        <label htmlFor={t('enable_automatic_evaluation')} className='ps-2 cm-sm-txt text-blue-gray-700 fw-medium'>{t('enable_automatic_evaluation')}</label>
                                    </div>
                                </div>
                                {showHasWordLimit &&
                                    <div>
                                        <div className="minimumWordCountBox border border-blue-gray-300">
                                            <div className="minimumWordCountInputBox" >
                                                <input type="number" onWheel={(e) => e.target.blur()} value={wordLimit} onChange={(e) => { setWordLimit(e.target.value) }} />
                                            </div>
                                            <div className="w-100 d-flex align-items-center justify-content-center">
                                                <span className="text-blue-gray-700 cm-xs-txt fw-medium">words</span>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>

                            {enableAutomaticEvaluation &&
                                <div className="enabled_automatic_evaluation_box">
                                    <div className="cm-create-task-form long_answer_question_type_select-box">
                                        <div className="mt-3 col-sm-12">
                                            <div className="form-group">
                                                <label htmlFor="long_answer_question_type_select" className='pb-2 text-blue-gray-700 cm-xs-txt fw-medium'>Type : </label>
                                                <Select
                                                    id="long_answer_question_type_select"
                                                    value={longAnswerQuestionType}
                                                    onChange={e => handleLongAnswerSelectChange(e)}
                                                    renderValue={(selected) => {
                                                        return <span className='cm-sm-txt fw-medium  text-blue-gray-700'>{selected}</span>;
                                                    }}
                                                    style={{ height: '35px' }}
                                                // className="long_answer_question_type_select-box"
                                                >
                                                    {loadSelectOptions(['Essay', 'Precis'])}
                                                </Select>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="cm-create-task-form">
                                        <div className="mt-3 col-sm-12">
                                            <div className="form-group">
                                                <label className='pb-2 text-blue-gray-700 cm-xs-txt fw-medium'>Minimum count :</label>
                                                <div className="minimumWordCountBox border border-blue-gray-300">
                                                    <div className="minimumWordCountInputBox" >
                                                        <input type="number" onWheel={(e) => e.target.blur()} value={minimumWordCount} onChange={(e) => { setMinimumWordCount(e.target.value) }} />
                                                    </div>
                                                    <div className="w-100 d-flex align-items-center justify-content-center">
                                                        <span className="text-blue-gray-700 cm-xs-txt fw-medium">words</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }


                        </>
                    }

                    <div className="form-group mb-3 pt-4">
                        <label className="pb-2 text-blue-gray-700 cm-xs-txt pb-2 fw-medium">{enableAutomaticEvaluation ? "Reference text" : "Answer Description"}</label>
                        <TextEditor
                            data={answerKeyData}
                            handeTxtEditorChange={(data) => {
                                setAnswerKeyData(data);
                            }}
                            id="answerDescriptionId"
                            sharedSpacesConfig={sharedSpacesConfig}
                        />
                        {dataObj.answerDescription.msg !== null && <span className='cm-xs-txt text-danger fw-medium pt-2'>{dataObj.answerDescription.msg}</span>}
                    </div>

                    {enableAutomaticEvaluation &&
                        <div className="border-bottom border-blue-gray-300 pb-1 mb-2">
                            <div className="mb-2 d-flex justify-content-between">
                                <span className='text-blue-gray-700 cm-xs-txt fw-medium cm-pointer ' onClick={() => { setShowAdvanceOptions(!showAdvanceOptions) }}>Advance options<FontAwesomeIcon icon={showAdvanceOptions ? regular("angle-up") : regular("angle-down")} className="ms-1" /></span>
                                {showAdvanceOptions &&
                                    <div className='d-flex align-items-end'>
                                        <div className='d-flex align-items-center user-select-none'>
                                            <input type="checkbox" name={t('assess_readability')} id={t('assess_readability')} className="cm-pointer" checked={readability} onChange={(e) => { setReadability(e.target.checked) }} />
                                            <label htmlFor={t('assess_readability')} className='ps-2 cm-sm-txt text-blue-gray-700 fw-medium'>{t('assess_readability')}</label>
                                        </div>
                                    </div>
                                }
                            </div>
                            {showAdvanceOptions &&
                                <div className="mb-2 d-flex flex-column" style={{ width: 'max-content' }}>
                                    <div className="w-100 d-flex gap-2 align-items-center my-2" >
                                        <span className='w-50 text-blue-gray-700 cm-xs-txt fw-medium'>Coherence to topic :</span>
                                        <div className="minimumWordCountBox border border-blue-gray-300">
                                            <div className="minimumWordCountInputBox" >
                                                <input type="number" onWheel={(e) => e.target.blur()} value={coherence} onChange={(e) => { setCoherence(e.target.value) }} />
                                            </div>
                                            <div className="w-100 d-flex align-items-center justify-content-center">
                                                <span className="text-blue-gray-700 cm-xs-txt fw-medium">% marks</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="w-100 d-flex gap-2 align-items-center my-2">
                                        <span className='w-50 text-blue-gray-700 cm-xs-txt fw-medium'>Grammar :</span>
                                        <div className="minimumWordCountBox border border-blue-gray-300">
                                            <div className="minimumWordCountInputBox" >
                                                <input type="number" onWheel={(e) => e.target.blur()} value={grammar} onChange={(e) => { setGrammar(e.target.value) }} />
                                            </div>
                                            <div className="w-100 d-flex align-items-center justify-content-center">
                                                <span className="text-blue-gray-700 cm-xs-txt fw-medium">% marks</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="w-100 d-flex gap-2 align-items-center my-2">
                                        <span className='w-50 text-blue-gray-700 cm-xs-txt fw-medium'>Spelling :</span>
                                        <div className="minimumWordCountBox border border-blue-gray-300">
                                            <div className="minimumWordCountInputBox" >
                                                <input type="number" onWheel={(e) => e.target.blur()} value={spelling} onChange={(e) => { setSpelling(e.target.value) }} />
                                            </div>
                                            <div className="w-100 d-flex align-items-center justify-content-center">
                                                <span className="text-blue-gray-700 cm-xs-txt fw-medium">% marks</span>
                                            </div>
                                        </div>
                                    </div>

                                    {longAnswerQuestionType === "Essay" &&
                                        <div className="w-100 d-flex gap-2 align-items-center my-2">
                                            <span className='w-50 text-blue-gray-700 cm-xs-txt fw-medium'>Vocabulary :</span>
                                            <div className="minimumWordCountBox border border-blue-gray-300">
                                                <div className="minimumWordCountInputBox" >
                                                    <input type="number" onWheel={(e) => e.target.blur()} value={vocabulary} onChange={(e) => { setVocabulary(e.target.value) }} />
                                                </div>
                                                <div className="w-100 d-flex align-items-center justify-content-center">
                                                    <span className="text-blue-gray-700 cm-xs-txt fw-medium">% marks</span>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                </div>
                            }
                        </div>
                    }
                </div>

                <CustomDialog
                    dialogHeading="Go Back"
                    cancelBtnLabel="Cancel"
                    actionBtnLabel={<span>Exit</span>}
                    actionBtnBg="bg-action-blue"
                    cancelFn={handleExitCancelFn}
                    actionFn={handleExitActionFn}
                    dialogStatus={dialogStatus}
                >
                    <p className='my-0 cm-sm-txt text-blue-800'>Are you sure you want to Exit? All the changes will be reverted as well.</p>
                </CustomDialog>
            </div >
        </>
    )
}

export default CreateKnowledgeBasedQuestion