import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomDialog from '../../components/custom-dialog/CustomDialog.component';
import ErrorController from '../../pages/error/ErrorController.page';
import { updateQuestData, updateTestCaseData } from '../../redux/actions/question.actions';
import { openSnackBar } from '../../redux/actions/snackbar.actions';
import { getApiUrl } from '../../utils/apiUrls';
import instance from '../../utils/axios';
import { arraysEqual, getEditorDataById, handleCodeChange } from '../../utils/helperFn';
import AddSolutionPreviewForm from './AddSolutionPreviewForm.container';
import AddTestCasesForm from './AddTestCasesForm.container';
import QuestionCreationSidebar from './QuestionCreationSidebar.container';
import QuestionDetailsForm, { inputDataType, outputDatatype } from './QuestionDetailsForm.container';

const CreateQuestionContainer = ({ t, getQuestId, getReqId, initStep, questData }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = React.useState(false);
    const [isCodeCompiling,setIsCodeCompiling]=React.useState(false);
    const [questStepData, setQuestStepData] = React.useState({
        activeStep: initStep,
        questId: !!getQuestId ? getQuestId : null,
        ...questData
    });
    const [dialogStatus, setDialogStatus] = React.useState({ status: false });
    const [enableCases, setEnableCases] = React.useState(null);
    const [compileTestCases, setCompileTestCases] = React.useState([]);
    const [allTestcaseRun, setAllTestcaseRun] = React.useState(-1);
    const [compilationError, setCompilationError] = React.useState(null);
    const [questId, setQuestId] = React.useState(0);

    const chkError = useSelector((state) => state.error);

    const [exitDialogStatus, setExitDialogStatus] = React.useState(false);

    React.useEffect(() => {
        setQuestStepData({
            ...questStepData,
            ...questData
        });
        if (questData.questStatus != null) {
            if (questData.questStatus.creator === "SentForReview") {
                navigate(`/question-library/${getReqId}`)
            }
        }
    }, [questData]);

    const loadStep = () => {
        switch (questStepData.activeStep) {
            case 0:
                return <QuestionDetailsForm questStepData={questStepData} getQuestDetailsValue={getQuestDetailsValue} />
            case 1:
                return <AddTestCasesForm questStepData={questStepData} getTestCaseValue={getTestCaseValue} />
            case 2:
                return <AddSolutionPreviewForm questId={questId} questStepData={questStepData} getSolutionValue={getSolutionValue} checkCodeCompile={checkCodeCompile} enableCases={enableCases} compileTestCases={compileTestCases} isLoading={isCodeCompiling} compilationError={compilationError} handleEnableCases={handleEnableCases} />
            default:
                return <QuestionDetailsForm questStepData={questStepData} getQuestDetailsValue={getQuestDetailsValue} />
        }
    }

    const handleSaveBtn = async () => {
        if (questStepData.activeStep < 3) {
            if (questStepData.activeStep === 0) saveQuestionDetails();

            if (questStepData.activeStep === 1) saveTestcases();

            if (questStepData.activeStep === 2) saveSolutionPreview();
        }
    }

    const handleBackBtn = () => {
        setExitDialogStatus(true);
    }


    const getQuestDetailsValue = (val) => {
        dispatch(updateQuestData({
            questDetails: val
        }))
    }

    const getTestCaseValue = (val) => {
        dispatch(updateTestCaseData(val))
    }

    const getSolutionValue = (val) => {
        dispatch(updateQuestData({
            addSolution: val
        }))
    }

    // const codeTemplate=()=>
    // {
    //     const res = instance.get(`${getApiUrl('getCodingTemplate')}?questionId=9&codeLanguage=cpp`);
    //     console.log(res);

    // }

    // codeTemplate();


    const handleQuestDetValidation = () => {
        let dataObj = questStepData.questDetails;
        let req_msg = 'Error: Please provide an input';

        const txtFields = ['questionTopic', 'functionName', 'questionTitle', 'questionText'];
        txtFields.forEach((el, index) => {
            if (el !== 'functionName') { dataObj[el].msg = dataObj[el].value === '' ? req_msg : null }
            else {

                dataObj[el].msg = dataObj[el].value === '' || dataObj[el].value.split(" ").length !== 1 ? "Error: Please provide a valid function name. It can only be of one word." : null
            }

        });


        // const singleValSelect = ['difficultyLevel']
        dataObj.difficultyLevel.msg = !!dataObj.difficultyLevel.value?.value ? null : req_msg;

        const multiSelectFields = ['questionTag', 'codingLanguages', 'addInputs', 'addOutputs'];
        multiSelectFields.forEach(el => dataObj[el].msg = Array.isArray(dataObj[el].value) && dataObj[el].value.length > 0 ? null : req_msg);

        //Check Input and Output values
        // if (dataObj.addInputs.value.length > 0) {
        //     dataObj.addInputs.value.forEach((el, index) => {
        //         let isInpValid = true;
        //         //console.log(dataObj.addInputs.value);
        //         for(let i=0; i < inputDataType.length; i++) {
        //             //console.log(dataType[i].message);
        //             //console.log(el.inpValue);
        //             if(inputDataType[i].preText === el.preText) {
        //                 isInpValid = false;
        //                 dataObj.addInputs.value[index].msg = !isInpValid ? "Please complete provided input specification" : null;
        //             }
        //         }

        //         //console.log('Check', el.inpValue, !isInpValid )

        //         dataObj.addInputs.value[index].msg  = !isInpValid ? req_msg : null;
        //     })   
        // }

        //console.log('dataObj.addInputs.value', dataObj.addInputs.value)

        // if (dataObj.addOutputs.value.length > 0) {
        //     dataObj.addOutputs.value.forEach((el, index) => {
        //         dataObj.addOutputs.value[index].msg = el.inpValue === '' ? req_msg : null;
        //     })
        // }


        dispatch(updateQuestData({
            questDetails: dataObj
        }))

        let isValid = true;


        for (const key of Object.keys(dataObj)) {
            if (key === 'addInputs' || key === 'addOutputs') {
                dataObj[key].value.every((el, index) => {
                    if (el.msg !== null) {
                        //console.log('Break', el)
                        isValid = false;
                        return isValid;
                    }
                })
            }

            if (dataObj[key].msg !== null) {
                isValid = false;
                return isValid;
            }
        }

        return isValid;

    }

    const handleTestcaseInpValidation = () => {
        let dataArr = JSON.parse(JSON.stringify(questStepData.addTestcases.data));
        let req_msg = 'Error: Please provide an input';
        // console.log("dataArr",dataArr);
        dataArr.forEach((el, index) => {
            el.testcaseType.msg = el.testcaseType.value === '' ? req_msg : null;
            el.testcaseName.msg = el.testcaseName.value === '' ? req_msg : null;

            const content = getEditorDataById(`testCaseDesc-${index}`);
            el.testcaseDesc.value = content;

            if (el.testcaseType.value.value.includes("Default")) {
                el.testcaseDesc.msg = content === "" ? req_msg : null;
            } else {
                el.testcaseDesc.msg = null
            }

            if (el.testcaseType.value.value !== "Default Testcase") {
                el.weightage.msg = el.weightage.value === 0 ? req_msg : null;
            } else {
                el.weightage.msg = null
            }

            if (el.testcaseType.value.value === "Time Complexity Testcase") {
                el.size.msg = el.size.value.value === null ? req_msg : null;
            } else {
                el.size.msg = null
            }

            el.inputArr.forEach((innerEl, index) => {
                const [isValid, msg] = validateIO(innerEl.value, questStepData.questDetails.addInputs.value[index].value)
                // if(!validateIO(innerEl.value,questStepData.questDetails.addInputs.value[index].value))
                innerEl.msg = !isValid ? msg : null;
            })
            el.outputArr.forEach((innerEl, index) => {
                const [isValid, msg] = validateIO(innerEl.value, questStepData.questDetails.addOutputs.value[index].value)
                innerEl.msg = !isValid ? msg : null;
            })

            //console.log("find me",el);
        });

        dispatch(updateTestCaseData(dataArr));

        let isValid = true;

        for (let el of dataArr) {
            for (let innerEl of el.inputArr) {
                if (innerEl.msg !== null) {
                    isValid = false;
                    return isValid;
                }
            }

            for (let innerEl of el.outputArr) {
                if (innerEl.msg !== null) {
                    isValid = false;
                    return isValid;
                }
            }

            if (el.testcaseDesc.msg !== null || el.testcaseName.msg !== null || el.testcaseType.msg !== null || el.weightage.msg !== null || el.size.msg !== null) {
                isValid = false;
                return isValid;
            }
        }

        return isValid;
    }

    function checkIFAllRowsAreCorrect(arrayInput) {
        if (arrayInput.length <= 0)
            return false;
        const lengthOfFirstRow = arrayInput[0].length;
        for (let i = 0; i < arrayInput.length; i++) {
            if (lengthOfFirstRow !== arrayInput[i].length) {
                return false;
            }
        }
        return true;
    }

    function validateIO(value, type) {
        // console.log("value type",value,type);
        if (type === 'int') {
            if (!/^-?\d+$/.test(value))
                return [false, "Error: Provide an integer value without white space."]
        }
        else if (type === 'int[]') {
            if (!/^\{-?\d+(,-?\d+)*\}$/.test(value))
                return [false, "Error: Provide a comma separated integer array enclosed in {} without white spaces."]

        }
        else if (type === 'int[-]') {
            let tempValue = value;
            tempValue = tempValue.slice(2, -2);
            const rows = tempValue.split("},{");
            const array = rows.map(row => row.split(",").map(Number));
            if (!/^\{{-?\d+(,-?\d+)*}(,{-?\d+(,-?\d+)*\})*}$/.test(value))
                return [false, "Error: Provide a comma separated 2d-integer array without white space."]
            if (!checkIFAllRowsAreCorrect(array))
                return [false, "Error: Size Mismatch : 2D array is not formed correctly."]

        }
        else if (type === 'string') {
            if (!/^[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?][a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/? ]*$/.test(value))
                return [false, "Error:Provide a valid string ( Only a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':\"\\|.<>\/? and whitespace is allowed )"]

        }
        else if (type === 'string[]') {
            if (!/^\{[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|.<>\/?][a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|.<>\/? ]*(,[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|.<>\/?][a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|.<>\/? ]*)*\}$/.test(value))
                return [false, "Error: Provide a valid string ( Only a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':\"\\|.<>\/? and whitespace is allowed is allowed ) and array of string is enclosed in {} without white space."]
        }
        else if (type === 'double[]') {
            if (!/^\{(-?\d+\.?(-?\d{2}))(,(-?\d+\.?(-?\d{2})))*\}$/.test(value))
                return [false, "Error: Provide a double value fixed 2 decimal places and  a comma separated double array enclosed in {} without white space."]
        }
        else if (type === 'double') {
            if (!/^-?\d+(\.?-?\d{2})$/.test(value))
                return [false, "Error: Provide a double value fixed 2 decimal places."]
        }
        return [true, ""];
    }

    // const handleTestcaseValidation = () => {
    //     let dataArr = questStepData.addTestcases.data;
    //     let isValid = true;
    //     const testCaseArr = dataArr.map(el => el.testcaseType.value.value);

    //     if (!testCaseArr.includes("Default Testcase")) {
    //         dispatch(openSnackBar({
    //             msg: 'Error: Default Testcase is mandatory',
    //             type: 'error'
    //         }))

    //         return false;
    //     }

    //     if (!testCaseArr.includes("Basic Testcase")) {
    //         dispatch(openSnackBar({
    //             msg: 'Error: Basic Testcase is mandatory',
    //             type: 'error'
    //         }))
    //         return false;
    //     }

    //     return isValid;
    // }

    var testCaseCounts = {};
    const [arr, setArr] = React.useState([]);

    const handleTestcaseValidation = () => {
        let dataArr = questStepData.addTestcases.data;
        let isValid = true;
        let testCaseCountsArr = [];
        const testCaseArr = dataArr.map(el => el.testcaseType.value.value);

        let nonWeightedTestcases = 0;
        let weightedTestcases = 0;

        for (const num of testCaseArr) {
            testCaseCounts[num] = testCaseCounts[num] ? testCaseCounts[num] + 1 : 1;
            if (num.includes("Default"))
                nonWeightedTestcases++;
            else
                weightedTestcases++;
        }
        Object.entries(testCaseCounts).forEach(([key, value]) => {
            testCaseCountsArr.push({ name: `${key}`, count: `${value}` })
        })
        setArr(testCaseCountsArr);

        //console.log("counts",testCaseCountsArr);
        //handleTestcaseInfoToggle(testCaseCounts);

        if (nonWeightedTestcases == 0) {
            dispatch(openSnackBar({
                msg: 'Error: Default Testcase is mandatory',
                type: 'error'
            }))
            return false;
        }
        if (weightedTestcases == 0) {
            dispatch(openSnackBar({
                msg: 'Error: Basic Testcase is mandatory',
                type: 'error'
            }))
            return false;
        }
        return isValid;

    }

    const handleAddSolutionValidation = () => {
        let dataObj = questStepData.addSolution;
        let req_msg = 'Error: Please provide an input';

        dataObj.ansDescription.msg = dataObj.ansDescription.value === '' ? req_msg : null;

        dataObj.selLang.msg = !!dataObj.selLang.value?.value ? null : req_msg;


        dispatch(updateQuestData({
            addSolution: dataObj
        }))

        let isValid = true;

        for (const key of Object.keys(dataObj)) {
            if (dataObj[key].msg !== null) {
                isValid = false;
                return isValid;
            }
        }

        return isValid;
    }

    const saveQuestionDetails = async () => {
        try {
            setIsLoading(true);
            const isFormValid = handleQuestDetValidation();

            if (!isFormValid) {
                setIsLoading(false);
                return;
            }

            const dataObj = questStepData.questDetails;

            const dataToSend = {
                codeLanguages: dataObj.codingLanguages.value.map(el => el.label),
                difficultyLevel: dataObj.difficultyLevel.value.value,
                functionName: dataObj.functionName.value,
                inputSpecification: dataObj.addInputs.value.map(el => el.preText),
                inputType: dataObj.addInputs.value.map(el => el.value),
                outputSpecification: dataObj.addOutputs.value.map(el => el.preText),
                outputType: dataObj.addOutputs.value.map(el => el.value),
                questionText: handleCodeChange(dataObj.questionText.value, "questionText"),
                questionTitle: dataObj.questionTitle.value,
                tagIds: dataObj.questionTag.value.map(el => el.value),
                taskId: getReqId,
                testcaseDetails: [],
                topicName: dataObj.questionTopic.value,
                ansLangId: !!questStepData.addSolution.selLang.value.id ? questStepData.addSolution.selLang.value.id : null,
                ansDescription: questStepData.addSolution.ansDescription.value,
                creatorStatus: "InProgress"
            }

            if (!!getQuestId) {
                //Check if Inputs and Outputs changed
                const isInpEqual = arraysEqual(dataToSend.inputType, questStepData.matchSpecs.addInputs.map(el => el.value));
                const isOutEqual = arraysEqual(dataToSend.outputType, questStepData.matchSpecs.addOutputs.map(el => el.value));
                //console.log('isInpEqual, isOutEqual', isInpEqual, isOutEqual);

                dataToSend.questionId = getQuestId;
                setQuestId(getQuestId)
                dataToSend.testCasesDTO = isInpEqual && isOutEqual ? questStepData.addTestcases.data.map(el => ({
                    description: el.testcaseDesc.value,
                    inputValues: el.inputArr.map(innerEl => innerEl.value),
                    name: el.testcaseName.value,
                    outputValues: el.outputArr.map(innerEl => innerEl.value),
                    type: el.testcaseType.value.value,
                    weightage: el.weightage.value,
                    size: el?.size ? el.size.value.value : null
                })) : null
            }

            const res = await instance.post(`${getApiUrl(!!getQuestId ? 'updateCodelysisQuestion' : 'saveCodelysisQuestion')}`, dataToSend);

            if (!!res.data.status) {
                setIsLoading(false);
                setTimeout(() => navigate(`/add-test-cases?reqId=${getReqId}&qId=${!!getQuestId ? getQuestId : res.data.id}`, { state: { fromAdd: true } }), 100)
            }

        } catch (error) {
            setIsLoading(false);
            console.log('error', error);
            // const msgObj = apiErrorHandler(error);
            // dispatch(openSnackBar({
            //     msg: t(msgObj.statusText),
            //     type: 'error'
            // }))
        }
    }

    const saveTestcases = async () => {
        try {
            setIsLoading(true);
            const isFormValid = handleTestcaseInpValidation();

            if (!isFormValid) {
                setIsLoading(false);
                return;
            }

            if (!handleTestcaseValidation()) {
                setIsLoading(false);
                return;
            }

            const dataObj = questStepData.questDetails;
            const dataToSend = {
                ansDescription: "",
                codeLanguages: dataObj.codingLanguages.value.map(el => el.label),
                difficultyLevel: dataObj.difficultyLevel.value.value,
                functionName: dataObj.functionName.value,
                inputSpecification: dataObj.addInputs.value.map(el => el.preText),
                inputType: dataObj.addInputs.value.map(el => el.value),
                outputSpecification: dataObj.addOutputs.value.map(el => el.preText),
                outputType: dataObj.addOutputs.value.map(el => el.value),
                questionText: dataObj.questionText.value,
                questionTitle: dataObj.questionTitle.value,
                tagIds: dataObj.questionTag.value.map(el => el.value),
                taskId: getReqId,
                topicName: dataObj.questionTopic.value,
                testCasesDTO: questStepData.addTestcases.data.map((el, index) => ({
                    description: getEditorDataById(`testCaseDesc-${index}`),//handleCodeChange(el.testcaseDesc.value, `testCaseDesc-${index}`),
                    inputValues: el.inputArr.map(innerEl => innerEl.value),
                    name: el.testcaseName.value,
                    outputValues: el.outputArr.map(innerEl => innerEl.value),
                    type: el.testcaseType.value.value,
                    weightage: parseFloat(el.weightage.value),
                    size: el?.size ? el.size.value.value : null
                })),
                ansLangId: !!questStepData.addSolution.selLang.value.id ? questStepData.addSolution.selLang.value.id : null,
                ansDescription: questStepData.addSolution.ansDescription.value,
                creatorStatus: "InProgress"
            }

            if (!!getQuestId) dataToSend.questionId = getQuestId;
            const res = await instance.post(`${getApiUrl('updateCodelysisQuestion')}`, dataToSend);

            if (!!res.data.status) {
                setIsLoading(false);
                handleDialogToggle(true);
                setQuestId(res.data.id);
                //navigate(`/add-solution?reqId=${getReqId}&qId=${!!getQuestId ? getQuestId : res.data.id}`, { state: { fromAdd: true } });
            }

        } catch (error) {
            setIsLoading(false);
            console.log('error', error);
            // const msgObj = apiErrorHandler(error);
            // dispatch(openSnackBar({
            //     msg: t(msgObj.statusText),
            //     type: 'error'
            // }))
        }
    }

    const handleDialogToggle = (status) => {
        setDialogStatus({
            ...dialogStatus,
            status: status
        });
    }

    const checkCodeCompile = async (state) => {
        try {
            setIsCodeCompiling(true);
            setEnableCases(state);
            const isFormValid = handleAddSolutionValidation();

            if (!isFormValid) {
                setIsCodeCompiling(false);
                return;
            }

            const dataObj = questStepData.questDetails;

            const dataToSend = {
                ansLangId: questStepData.addSolution.selLang.value.id,
                ansDescription: questStepData.addSolution.ansDescription.value,
                creatorStatus: "completed"
            }

            if (!!getQuestId) dataToSend.questionId = getQuestId;

            const res = await instance.post(`${getApiUrl('checkCodeCompilation')}`, dataToSend);
            if (res.data) {
                setIsCodeCompiling(false);
                if (!!res.data.status) {
                    let count = 0;
                    setCompilationError(null);
                    setCompileTestCases(res.data.testcaseResponseDtoList);
                    if (res.data.status) {
                        for (var i = 0; i < res.data.testcaseResponseDtoList.length; i++) {
                            if (res.data.testcaseResponseDtoList[i].status)
                                count = count + 1;
                        }
                        if (count === res.data.testcaseResponseDtoList.length) {
                            setAllTestcaseRun(1);
                            setEnableCases("enable");
                        }
                        else
                            setAllTestcaseRun(0);
                        // navigate(`/add-solution?reqId=${getReqId}&qId=${!!getQuestId ? getQuestId : res.data.id}`, { state: { fromAdd: true } });
                    }
                }
                else {
                    setCompilationError(res.data.message);
                }
            }

        } catch (error) {
            setIsCodeCompiling(false);
            console.log('error', error);
        }
    }

    const handleEnableCases = (state) => {
        setEnableCases(state);
    }

    const dialogActionFn = () => {
        viewTestcases();
        setDialogStatus({
            ...dialogStatus,
            status: false
        })
    }

    const viewTestcases = () => {
        navigate(`/add-solution?reqId=${getReqId}&qId=${!!getQuestId ? getQuestId : questId}`, { state: { fromAdd: true } });


    }

    const saveSolutionPreview = async () => {
        try {
            setIsLoading(true);
            const isFormValid = handleAddSolutionValidation();

            if (!isFormValid) {
                setIsLoading(false);
                return;
            }

            const dataObj = questStepData.questDetails;

            const dataToSend = {
                codeLanguages: dataObj.codingLanguages.value.map(el => el.label),
                difficultyLevel: dataObj.difficultyLevel.value.value,
                functionName: dataObj.functionName.value,
                inputSpecification: dataObj.addInputs.value.map(el => el.preText),
                inputType: dataObj.addInputs.value.map(el => el.value),
                outputSpecification: dataObj.addOutputs.value.map(el => el.preText),
                outputType: dataObj.addOutputs.value.map(el => el.value),
                questionText: dataObj.questionText.value,
                questionTitle: dataObj.questionTitle.value,
                tagIds: dataObj.questionTag.value.map(el => el.value),
                taskId: getReqId,
                topicName: dataObj.questionTopic.value,
                testCasesDTO: questStepData.addTestcases.data.map(el => ({
                    description: el.testcaseDesc.value,
                    inputValues: el.inputArr.map(innerEl => innerEl.value),
                    name: el.testcaseName.value,
                    outputValues: el.outputArr.map(innerEl => innerEl.value),
                    type: el.testcaseType.value.value,
                    weightage: el.weightage.value
                })),
                ansLangId: questStepData.addSolution.selLang.value.id,
                ansDescription: questStepData.addSolution.ansDescription.value,
                creatorStatus: "completed"
            }


            if (!!getQuestId) dataToSend.questionId = getQuestId;

            const res = await instance.post(`${getApiUrl('updateCodelysisQuestion')}`, dataToSend);

            if (!!res.data.status) {
                setIsLoading(false);
                // navigate(`/add-solution?reqId=${getReqId}&qId=${!!getQuestId ? getQuestId : res.data.id}`, { state: { fromAdd: true } });
                // alert('Success')
                navigate(`/question-library/${getReqId}`);
            }

        } catch (error) {
            setIsLoading(false);
            console.log('error', error);
            // const msgObj = apiErrorHandler(error);
            // dispatch(openSnackBar({
            //     msg: t(msgObj.statusText),
            //     type: 'error'
            // }))
        }
    }

    const handleError = () => chkError.location === 'CreateQuestion';

    const handleExitCancelFn = () => {
        setExitDialogStatus(false);
    }

    const handleExitActionFn = () => {
        handleExitCancelFn();
        if (questStepData.activeStep > 0) {
            if (questStepData.activeStep === 2) {
                navigate(`/add-test-cases?reqId=${getReqId}&qId=${getQuestId}`)
            } else if (questStepData.activeStep === 1) {
                navigate(`/question-details?reqId=${getReqId}&qId=${getQuestId}`)
            }
        }
        else
            navigate(`/question-library/${getReqId}`);
    }


    return (
        handleError() ? <ErrorController data={chkError} /> : <>
            <div className="cm-create-event-container d-flex flex-wrap">
                <QuestionCreationSidebar isLoading={isLoading} t={t} allTestcaseRun={allTestcaseRun} questStepData={questStepData} handleSaveBtn={handleSaveBtn} handleBackBtn={handleBackBtn} compileTestCases={compileTestCases} enableCases={enableCases} />
                {loadStep()}
            </div>

            <CustomDialog
                dialogHeading="All Testcases"
                cancelBtnLabel="Cancel"
                actionBtnLabel={<span>Proceed to Next Step</span>}
                actionBtnBg="bg-action-blue"
                cancelFn={handleDialogToggle}
                actionFn={dialogActionFn}
                dialogStatus={dialogStatus.status}
            >
                <div className="cm-candidate-report-list-header d-flex align-items-center bg-blue-gray-50">
                    <div className='col-3 p-2'>
                        <p className='my-0 cm-sm-txt fw-medium text-blue-gray-700'>S.No.</p>
                    </div>
                    <div className='col-5 p-2'>
                        <p className='my-0 cm-sm-txt fw-medium text-blue-gray-700'>Testcase Type</p>
                    </div>
                    <div className='col-4 p-2'>
                        <p className='my-0 cm-sm-txt fw-medium text-blue-gray-700'>Testcase Count</p>
                    </div>

                </div>

                <>{arr?.map((user, index) => (
                    <div key={index} className="cm-candidate-report-list-content bg-white ">
                        <div className="cm-candidate-report-item d-flex align-items-center ">
                            <div className='col-3'>
                                <p className='my-0 cm-sm-txt fw-medium text-blue-800 p-2'>{index + 1}</p>
                            </div>
                            <div className='col-5'>
                                <p className='my-0 cm-sm-txt fw-medium text-blue-800 p-2'>{user.name}</p>
                            </div>
                            <div className='col-4'>
                                <p className='my-0 cm-sm-txt fw-medium text-blue-800 p-2'>{user.count}</p>
                            </div>
                        </div>
                    </div>
                ))}</>
            </CustomDialog>

            <CustomDialog
                        dialogHeading="Go Back"
                        cancelBtnLabel="Cancel"
                        actionBtnLabel={<span>Exit</span>}
                        actionBtnBg="bg-action-blue"
                        cancelFn={handleExitCancelFn}
                        actionFn={handleExitActionFn}
                        dialogStatus={exitDialogStatus}
                    >
                        <p className='my-0 cm-sm-txt text-blue-800'>Are you sure you want to Exit? All the changes will be reverted as well.</p>
            </CustomDialog>
        </>

    )

}

CreateQuestionContainer.defaultProps = {
    initStep: 0
}

export default CreateQuestionContainer;