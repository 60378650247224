import React from "react";
import BankQuestionPreviewCard from "../question-library-knowledgeBased/BankQuestionPreviewCard";
import AnimationDataLoader from "../../components/Loaders/animationData";
import { ReactComponent as EmptyIcon } from "../../assets/images/home/empty-data.svg";
import "./MyBank.css"
import { useDispatch, useSelector } from "react-redux";
import instance from "../../utils/axios";
import { getApiUrl } from "../../utils/apiUrls";
import { apiErrorHandler } from "../../utils/errorHandler";
import { openSnackBar } from "../../redux/actions/snackbar.actions";
import { useTranslation } from "react-i18next";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import NumberedPagination from "../../components/numbered-pagination/NumberedPagination.component";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Box, Chip, OutlinedInput, Select } from '@mui/material';
import PharseSearchModal from "../../pages/my-bank/PharseSearchModal";
import UploadBankQuestions from "../../pages/upload-questions/UploadBankquestionsModal";
import CustomToolTip from "../../components/custom-tooltip/CustomTooltip.component";
import { useNavigate } from "react-router-dom";
import CustomDialog from "../../components/custom-dialog/CustomDialog.component";

const BankQuestionsDashboard = ({ bankData, currBankId, bankId, getAllBankQuestions, permissions, isBankIdChanged, bankName }) => {
    const navigate = useNavigate();
    const user = useSelector(state => state.user);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [searchText, setSearchText] = React.useState("")
    const [commentModal, setCommentModal] = React.useState({
        reviewQuestionId: null,
        comments: null,
        isLoading: true,
        flagData: null,
        formView: "comment",
    });
    const difficultyLevels = React.useMemo(() => ([
        { label: "EASY", value: 'easy' },
        { label: "MEDIUM", value: 'medium' },
        { label: "DIFFICULT", value: 'difficult' }
    ]), []);
    const flagOptions = React.useMemo(() => ([
        { label: "ALL", value: 'all' },
        { label: "Has Flags", value: 'hasFlag' },
        { label: "No Flags", value: 'noFlag' }
    ]), []);
    const reviewStatus = React.useMemo(() => ([
        { label: "ALL", value: 'all' },
        { label: "Reviewed", value: 'reviewed' },
        { label: "Not Reviewed", value: 'notReviewed' }
    ]), []);
    const contiansField = React.useMemo(() => ([
        { label: "Contains Any", value: 'Or' },
        { label: "Contains All", value: 'And' }
    ]), []);
    const [modalStatus, setModalStatus] = React.useState(false)
    const [searchFieldobj, setSearchFieldObj] = React.useState({
        text: '',
        type: { label: "Contains Any", value: 'Or' }
    })
    const initialState = React.useMemo(() => ({
        searchField: { text: '', type: { label: "Contains Any", value: 'Or' } },
        diffcultyLevel: [{ label: "EASY", value: 'easy' },
        { label: "MEDIUM", value: 'medium' },
        { label: "DIFFICULT", value: 'difficult' }],
        flagStatus: { label: "ALL", value: 'all' },
        reviewStatus: { label: "ALL", value: 'all' }
    }), []);
    const [filterForm, setFilterForm] = React.useState(initialState)
    const [filterData, setFilterData] = React.useState(initialState)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleModalToggle = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [allChecked, setAllChecked] = React.useState(false);
    const [checkedQuestions, setCheckedQuestions] = React.useState([]);
    const selectRef = React.useRef();
    const [dialogStatus, setDialogStatus] = React.useState(false);

    React.useEffect(() => {
        getFlagData();
    }, []);
    const getFlagData = async () => {
        try {
            const res = await instance.get(`${getApiUrl("getFlags")}?category=review`);

            if (res.data) {
                setCommentModal({
                    ...commentModal, flagData: res.data.map(el => {
                        el.value = el.flagText;
                        el.label = <><FontAwesomeIcon icon={solid("flag")} style={{ color: el.flagColour }} /><span className="ps-2 cm-sm-txt text-blue-800">{el.flagText}</span></>
                        return el;
                    })
                })
            }

        } catch (error) {
            const errObj = apiErrorHandler(error);
            dispatch(openSnackBar({
                msg: t(errObj.statusText),
                type: 'error'
            }))
        }
    }

    const fetchAllComments = async (reviewQuestionId) => {
        setCommentModal({
            ...commentModal,
            reviewQuestionId,
            isLoading: true,
        })
        try {
            const res = await instance.get(`${getApiUrl("getAllComments")}?bankId=${bankId}&questionId=${reviewQuestionId}&questionLanguage=BASE`);
            setCommentModal({
                ...commentModal,
                reviewQuestionId,
                isLoading: false,
                comments: res.data,
                formView: "comment",
            })

        } catch (error) {
            setCommentModal({
                ...commentModal,
                isLoading: false,
            })
            const errObj = apiErrorHandler(error);
            dispatch(openSnackBar({
                msg: t(errObj.statusText),
                type: 'error'
            }))
        }
    }

    const handleCommentModalToggle = (reviewQuestionId = null) => {
        if (reviewQuestionId === null || reviewQuestionId === commentModal.reviewQuestionId) {
            setCommentModal({ ...commentModal, reviewQuestionId: null })
        } else {
            fetchAllComments(reviewQuestionId)
        }
    }
    const handleSearch = () => {
        const dataToSend = {
            searchField: { text: searchFieldobj?.text, type: searchFieldobj?.type?.value },
            diffcultyLevel: filterForm.diffcultyLevel,
            flagStatus: filterForm.flagStatus,
            reviewStatus: filterForm.reviewStatus
        }
        getAllBankQuestions(bankData.revieweBankId, 0, dataToSend);
    }

    const handleApply = () => {
        const dataToSend = {
            searchField: searchFieldobj.text ? { text: searchFieldobj?.text, type: searchFieldobj?.type?.value } : null,
            diffcultyLevel: filterForm.diffcultyLevel,
            flagStatus: filterForm.flagStatus,
            reviewStatus: filterForm.reviewStatus
        }
        setAnchorEl(null);
        setFilterData(dataToSend);
        getAllBankQuestions(bankData.revieweBankId, 0, dataToSend);
    }

    const handleReset = () => {
        setFilterData(initialState);
        setFilterForm(initialState)
    }

    const removeDifficultyLevel = (e, value) => {
        setFilterForm({
            ...filterForm,
            diffcultyLevel: filterForm.diffcultyLevel.filter(el => el.value !== value)
        })
    }

    const handleChange = (key, value) => {
        let dataToSet = value;
        if (key === "difficultyLevel") {
            dataToSet = typeof value === 'string' ? value.split(',') : value;
        }
        if (key === "flagStatus") {
            dataToSet = flagOptions.find(el => el.value === value);
        }
        if (key === 'reviewStatus') {
            dataToSet = reviewStatus.find(el => el.value === value);
        }
        if (key === 'type' || key === 'text') {
            if (key === 'type') {
                dataToSet = contiansField.find(el => el.value === value);
            }
            setSearchFieldObj({
                ...searchFieldobj,
                [key]: dataToSet
            })
        }
        else {
            setFilterForm({
                ...filterForm,
                [key]: dataToSet
            })
        }
    }

    const handleClearFilters = () => {
        setFilterForm(initialState);
        setFilterData(initialState);
        getAllBankQuestions(bankData.revieweBankId, 0, initialState);
    }
    const handleToggleModal = () => {
        setModalStatus(!modalStatus);
    }

    const loadDifficultyLevels = (list) => list.map(el => el.label).join(' , ');
    const loadSelectMenuItem = (list) => list.map(el => <MenuItem value={el.value} key={el.value}>{el.label}</MenuItem>)

    const handleSaveComment = async (reviewQuestionId, dataToSend, invalidMsg = null) => {
        try {
            if (invalidMsg) {
                dispatch(openSnackBar({ type: 'error', msg: invalidMsg }));
                return;
            }
            const res = await instance.post(`${getApiUrl("saveComment")}?bankId=${bankId}&questionId=${reviewQuestionId}&questionLanguage=BASE`, dataToSend);

            if (res.data?.status) {
                dispatch(openSnackBar({ type: "success", msg: res.data.message }))

                fetchAllComments(reviewQuestionId);
            }

        } catch (error) {
            setCommentModal({
                ...commentModal,
                isLoading: false,
            })
            const errObj = apiErrorHandler(error);
            dispatch(openSnackBar({
                msg: t(errObj.statusText),
                type: 'error'
            }))
        }
    }

    const handleFormView = (formView) => {
        setCommentModal({ ...commentModal, formView })
    }

    const getQuestions = () => (bankData?.questionData?.map((element, index) => <BankQuestionPreviewCard handleFormView={handleFormView} handleSaveComment={handleSaveComment} t={t} isCommentModalOpen={element.reviewQuestionId === commentModal.reviewQuestionId} handleCommentModalToggle={handleCommentModalToggle} commentModal={commentModal} role={user.role} key={index} question={element} permissions={permissions} questionType={element.displayType} bankId={bankId} checkedQuestions={checkedQuestions} setCheckedQuestions={setCheckedQuestions} setAllChecked={setAllChecked} totalQuestions={bankData?.questionData ? bankData?.questionData.length : 0} selectRef={selectRef} handleSelectedDelete={handleDeleteSelectedQuestion} getPermission={getPermission} bankName={bankName} />))

    const handlePageChange = (page) => {
        let currentPage = bankData.pageIndex;
        if (page === 'next') {
            currentPage = bankData.pageIndex + 1;
        } else if (page === 'prev') {
            currentPage = bankData.pageIndex - 1;
        } else {
            currentPage = page;
        }
        if (bankData.pageIndex !== currentPage) getAllBankQuestions(bankData.revieweBankId, currentPage - 1, filterForm)

    }

    const handleDeleteSelectedQuestion = async (questionIds) => {
        const dataToSend = {
            questionIds: questionIds,
            bankId: bankId
        };

        try {
            const res = await instance.post(getApiUrl("deleteQuestions"), dataToSend);
            if (res?.data.status) {
                navigate(0);
                dispatch(openSnackBar({
                    msg: t(res.data.message),
                    type: 'success'
                }))
            }

        } catch (error) {
            const errObj = apiErrorHandler(error);
            dispatch(openSnackBar({
                msg: t(errObj.statusText),
                type: 'error'
            }))
        }
    }

    const handleSelectAllChange = (event) => {
        if (event.target.checked) {
            const questionIds = bankData?.questionData?.map((element, index) => {
                return element.reviewQuestionId;
            });
            setCheckedQuestions(questionIds);
        } else {
            setCheckedQuestions([]);
        }

        setAllChecked(event.target.checked);
    }

    const getPermission = (key) => {
        if (user.role === "ADMIN") return true;
        return permissions && permissions[key]
    }

    const handleDialogToggle = () => {
        setDialogStatus(false);
    }

    const dialogActionFn = () => {
        if (checkedQuestions?.length) {
            setDialogStatus(false);
            handleDeleteSelectedQuestion(checkedQuestions)
        }
    }

    return (
        <>
            <div className="cm-event-library-content-wrapper cm-bank-content-wrapper bg-blue-gray-50 mb-4 mx-auto">

                <div className="d-flex align-items-center mt-3" style={{ height: '40px' }}>
                    <div className="me-2 d-flex" >
                        <CustomToolTip title={"Select All"}>
                            <input type="checkbox" name="question-select-all" id="question-select-all"
                                onChange={(e) => handleSelectAllChange(e)}
                                checked={allChecked}
                                ref={selectRef}
                                style={{ transform: 'scale(1.1)', cursor: 'pointer' }}
                            />
                        </CustomToolTip>
                    </div>

                    <CustomToolTip title={"Delete"}>
                        <div className={`me-2 d-flex align-items-center justify-content-center delete-btn trash-icon ${(checkedQuestions?.length && getPermission("canDeleteQuestion")) ? 'cm-pointer' : 'cm-disabled cm-pointer-none'}`}
                            onClick={() => { setDialogStatus(true) }}>
                            <FontAwesomeIcon icon={regular("trash")} />
                        </div>
                    </CustomToolTip>

                    <div className="d-inline-flex border" style={{ width: 'calc(100% - 190px)', height: 'auto' }}>
                        <div className="d-flex cm-contains-field" style={{ height: 'auto' }}>
                            <Select
                                id="contains-select"
                                value={searchFieldobj.type.value}
                                onChange={e => handleChange('type', e.target.value)}>
                                {loadSelectMenuItem(contiansField)}
                            </Select>
                        </div>
                        <div className="searchtext-field position-relative">
                            <input
                                type="text"
                                value={searchFieldobj.text}
                                onChange={(e) => handleChange('text', e.target.value)}
                                name="search"
                                placeholder={"Enter question text"}
                                className="user-input-search-bar pe-2"
                                id="questionText"
                            />
                            <FontAwesomeIcon
                                icon={regular("search")}
                                className="user-input-icon  position-absolute"
                            />
                        </div>
                        <div className="d-flex align-items-center cm-info-icon">
                            <FontAwesomeIcon
                                icon={regular("circle-info")}
                                className="user-info-icon ms-1 me-1 cm-pointer"
                                onClick={handleToggleModal} />
                        </div>
                    </div>
                    <div className="ms-1 d-flex" style={{ height: '40px' }}>
                        <button
                            type="submit"
                            className="ms-1 cm-add-quest-btn bg-action-blue border border-action-blue text-white cm-sm-txt fw-medium cm-pointer cm-xs-txt"
                            style={{ height: '40px' }}
                            onClick={handleSearch}
                        >
                            Search
                        </button>

                        <span
                            onClick={handleModalToggle}
                            id="addFilterButton"
                            className="ms-2 cm-add-quest-btn bg-action-blue border border-action-blue text-white cm-sm-txt fw-medium cm-pointer"
                            style={{ height: '40px' }}
                        >
                            <FontAwesomeIcon
                                icon={regular("Filters")}
                            />
                        </span>
                        <Menu
                            id="add-filter-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'add-filter',
                            }}
                            PaperProps={{
                                style: {
                                    width: 400,
                                    marginTop: 10
                                },
                            }}
                        >
                            <MenuItem onClick={null} disableRipple={true} className="bg-white d-flex px-3">
                                <div className="mt-3 cm-create-task-form cm-add-filter px-2 pb-2">
                                    <label className='cm-sm-txt text-blue-gray-700 mb-2 fw-medium'>Difficulty Level :</label>
                                    <Select
                                        value={filterForm.diffcultyLevel}
                                        onChange={(e) => handleChange('diffcultyLevel', e.target.value)}
                                        input={<OutlinedInput />}
                                        multiple
                                        renderValue={(selected) => {
                                            return (
                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                    {selected.map((el) => (
                                                        <Chip clickable deleteIcon={
                                                            <FontAwesomeIcon icon={regular("times-circle")} onMouseDown={(event) => event.stopPropagation()} />
                                                        } onDelete={(e) => removeDifficultyLevel(e, el.value)} key={el.value} label={<><span>{el.label}</span></>} />
                                                    ))}
                                                </Box>
                                            )
                                        }}
                                        autoFocus={false}
                                    >
                                        {difficultyLevels.map((el) => {
                                            const isAdded = filterForm.diffcultyLevel.some(innerEl => innerEl.value === el.value);
                                            return (
                                                <MenuItem
                                                    key={el.value}
                                                    value={el}
                                                    disabled={isAdded}
                                                    className="cm-sm-txt fw-medium"
                                                >
                                                    {`${el.label}`}
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>

                                    <label className='cm-sm-txt mt-3 text-blue-gray-700 mb-2 fw-medium'>Flag Status :</label>
                                    <Select
                                        id="flag-select"
                                        value={filterForm.flagStatus.value}
                                        onChange={e => handleChange('flagStatus', e.target.value)}>
                                        {loadSelectMenuItem(flagOptions)}
                                    </Select>
                                    <label className='cm-sm-txt mt-3 text-blue-gray-700 mb-2 fw-medium'>Review Status :</label>
                                    <Select
                                        id="review-select"
                                        value={filterForm.reviewStatus.value}
                                        onChange={e => handleChange('reviewStatus', e.target.value)}>
                                        {loadSelectMenuItem(reviewStatus)}
                                    </Select>
                                </div>
                            </MenuItem>
                            <MenuItem onClick={null} disableRipple={true} className="bg-white cm-filter-actions d-flex justify-content-end px-3">
                                <span onClick={handleReset} className='cm-Reset-btn btn cm-btn-trans cm-sm-txt fw-medium bg-white text-action-blue border border-action-blue'>Reset</span>
                                <span onClick={handleApply} className='ms-3 cm-apply-btn btn cm-btn cm-sm-txt fw-medium bg-action-blue text-white border border-action-blue'>Apply</span>
                            </MenuItem>
                        </Menu>
                    </div>
                </div>
                <div className="d-flex justify-content-end px-3 mt-3">
                    {filterData.diffcultyLevel.length < 3 && <label className="cm-candidate-pill  fw-medium  my-auto ms-2 cm-xs-txt fw-medium text-blue-800">Diffculty Levels :  {loadDifficultyLevels(filterData.diffcultyLevel)}</label>}
                    {filterData.flagStatus.value != 'all' && <label className="cm-candidate-pill me-2 fw-medium  my-auto ms-2 cm-xs-txt fw-medium text-blue-800">Flag Status : {filterData.flagStatus.label}</label>}
                    {filterData.reviewStatus.value != 'all' && <label className="cm-candidate-pill me-2 fw-medium  my-auto  cm-xs-txt fw-medium text-blue-800">Review Status : {filterData.reviewStatus.label}</label>}
                    {(filterData.diffcultyLevel.length < 3 || filterData.flagStatus.value != 'all' || filterData.reviewStatus.value != 'all') && <span onClick={handleClearFilters} className="p-2 fw-medium  my-auto cm-xs-txt fw-medium text-blue-800 cm-pointer"> Clear All </span>}

                </div>

                {!bankData.isLoading ? (bankData?.questionData.length > 0 ? <div className="cm-event-bank-questions px-3 mt-3">
                    {getQuestions()}
                </div> : <div className="d-flex cm-empty-container justify-content-center">
                    <div className="text-center px-4">
                        <EmptyIcon />
                        <p className="text-blue-700 mt-3 mb-0 fw-semibold">
                            Nothing to see!
                        </p>
                        <p
                            className="text-blue-gray-500 mt-2 mb-0 cm-sm-txt mt-2 mx-auto"
                            style={{ maxWidth: "368px" }}
                        >
                            You can start uploading  questions.
                        </p>
                    </div>
                </div>)
                    : (<div className="d-flex cm-question-progress-bar justify-content-center align-items-center">
                        <AnimationDataLoader />
                    </div>)
                }

                <CustomDialog
                    dialogHeading="Delete Questions"
                    cancelBtnLabel="No, Don't Delete"
                    actionBtnLabel={<span>Delete Question</span>}
                    actionBtnBg="bg-danger"
                    cancelFn={handleDialogToggle}
                    actionFn={dialogActionFn}
                    dialogStatus={dialogStatus}
                >
                    <p className='my-0 cm-sm-txt text-blue-800'>Are you sure you want to delete the selected questions? All the data defined in these questions, will be removed as well.</p>
                </CustomDialog>

            </div>
            <div className="cm-event-pagination">
                <label className="my-2 ms-4 text-blue-gray-700 cm-sm-txt fw-semibold">Questions in current Page : {bankData.pageSize} </label>
                <div className="cm-event-pagenumbers d-flex">
                    {!isBankIdChanged ? (<NumberedPagination showNextPrevButtons={true} handlePagination={handlePageChange} totalPages={bankData.pageCount} pagesToShow={bankData.pageCount < 10 ? bankData.pageCount : 10} currentPage={bankData.pageIndex} />) : ""}
                </div>
            </div>
            <PharseSearchModal modalStatus={modalStatus} handleModalToggle={handleToggleModal} />

        </>
    )
}
export default BankQuestionsDashboard;
