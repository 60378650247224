import React, { useState } from 'react';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlag } from '@fortawesome/free-solid-svg-icons';
import ViewReviewerModal from './ViewReviewerModal.container';
import { useTranslation } from 'react-i18next';
import './QuestionLibrary.styles.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import CustomToolTip from '../../components/custom-tooltip/CustomTooltip.component';
import QuestionLibraryItem from '../../components/question-library-item/QuestionLibraryItem.component';
import ReviewTimelineModal from './ReviewTimelineModal.container';
import { useParams } from 'react-router-dom';
import instance from '../../utils/axios';
import { ReactComponent as EmptyIcon } from '../../assets/images/home/empty-data.svg';
import CustomDialog from '../../components/custom-dialog/CustomDialog.component';
import { useDispatch, useSelector } from 'react-redux';
import { openSnackBar } from '../../redux/actions/snackbar.actions';
import MetaComp from '../../components/meta-comp/MetaComp.component';
import { TextareaAutosize } from '@mui/material';
import { appError } from '../../redux/actions/error.action';
import ErrorController from '../../pages/error/ErrorController.page';
import { getApiUrl } from '../../utils/apiUrls';
import { saveAs } from 'file-saver';
import QuestionInstructionModal from './QuestionInstructionModal.container';
import QuestionAddModal from './QuestionAddModal.container';

import Select from 'react-select';

import MenuItem from '@mui/material/MenuItem';
import { Select as Muiselect } from '@mui/material';
import { faXmark } from '@fortawesome/pro-thin-svg-icons';
import { diSet, disselectALl, setRows } from '../../redux/actions/checkbox.actions';


const QuestionLibraryContainer = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { taskId } = useParams();
    const { state } = useLocation();
    const user = useSelector(state => state.user);
    const [comment, setComment] = React.useState("");
    const [selectedRows, setSelectedRows] = React.useState([]);
    const [dialogStatus, setDialogStatus] = React.useState({
        status: false,
        revStatus: false,
        approveStatus: false,
        reviewerStatus: false,
        qId: null,
        searchDTOList: [],
    });
    const [reviewModalStatus, setReviewModalStatus] = React.useState(false);
    const [reviewTimelineModalStatus, setReviewTimelineModalStatus] = React.useState(false);
    const [allData, setAllData] = React.useState(null);
    const [isAllDataLoading, setIsAllDataLoading] = React.useState(true);
    const [reload, setReload] = React.useState(false);
    const [timelineQuestionId, setTimeLineQuestionId] = React.useState(0);
    const [timelineQuestionStatus, setTimeLineQuestionStatus] = React.useState("");
    const [timelineAdminQuestionStatus, setTimelineAdminQuestionStatus] = React.useState("");
    const chkError = useSelector((state) => state.error);
    const [downloadQuestions, setDownloadQuestions] = React.useState([]);
    const [errorMsg, setErrorMsg] = React.useState(false);
    const [aStatus, setAStatus] = React.useState('Accept');

    // changes
    const [flagOptions, setFlagOptions] = React.useState(true);
    const [selectedFlag, setSelectedFlag] = React.useState('');
    const [reviewerAnswer, setReviewerAnswer] = React.useState("");

    // changes
    const options = [
        { value: 'Technically inaccurate', label: <><FontAwesomeIcon icon={faFlag} style={{ color: '#E74C3C' }} /> Technically inaccurate</> },
        { value: 'Ambiguous question text', label: <><FontAwesomeIcon icon={faFlag} style={{ color: '#647C55' }} /> Ambiguous question text</> },
        { value: 'Irrelevant to the skill', label: <><FontAwesomeIcon icon={faFlag} style={{ color: '#3498DB' }} /> Irrelevant to the skill</> },
        { value: 'Irrelevant to the sub-skill/topic', label: <><FontAwesomeIcon icon={faFlag} style={{ color: '#FF9900' }} /> Irrelevant to the sub-skill/topic</> },
        { value: 'None of the answer options are correct', label: <><FontAwesomeIcon icon={faFlag} style={{ color: '#DA3C78' }} /> None of the answer options are correct</> },
        { value: 'More than one correct answer options', label: <><FontAwesomeIcon icon={faFlag} style={{ color: '#39D5FF' }} /> More than one correct answer options</> },
        { value: 'Level of difficulty of the question is higher than expected', label: <><FontAwesomeIcon icon={faFlag} style={{ color: '#F1C40F' }} /> Level of difficulty of the question is higher than expected</> },
        { value: 'Level of difficulty of the question is lower than expected', label: <><FontAwesomeIcon icon={faFlag} style={{ color: '#9B59B6' }} /> Level of difficulty of the question is lower than expected</> },
        { value: 'Code snippets are not properly formatted/indented', label: <><FontAwesomeIcon icon={faFlag} style={{ color: '#E67E22' }} /> Code snippets are not properly formatted/indented</> },
        { value: 'Question is plagiarized', label: <><FontAwesomeIcon icon={faFlag} style={{ color: '#B3421B' }} /> Question is plagiarized</> },
    ];



    const sme = useSelector(state => state.user?.sme);
    const userId = useSelector(state => state.user.userId);

    const [checkedQuestion, setCheckedQuestion] = React.useState([]);
    const [checkedQuestionMap, setCheckedQuestionMap] = React.useState({});
    const [SelectAll, setSelectAll] = React.useState(false);
    const [question, setQuestion] = React.useState([])
    const [noofquestion, setNOOfQuestion] = React.useState(0);
    const [render, setRender] = React.useState(1);
    const [multipleQuestionAllocate, setMultipleQuestionAllocate] = React.useState(false);
    const selectquestions = useSelector(state => state.checkbox);
    const [canAddReviewer, setCanAddReviewer] = React.useState(false);
    const [questionReviewerStatusMap, setquestionReviewerStatusMap] = React.useState(new Map());
    const [questionAdminStatusMap, setquestionAdminStatusMap] = React.useState(new Map());
    const [questionStatusMap, setquestionStatusMap] = React.useState(new Map());
    const [canAcceptMultipleQuestions, setCanAcceptMultipleQuestions] = React.useState(true);
    const [canApproveMultipleQuestion, setCanApproveMultipleQuestion] = React.useState(true);
    let resetQuestionMap = {};
    const initialState = {
        selectFor: "",
        flagselcted: "",
        comments: null,
        isSelected: false,
        isMultiple: false,
        isRejected: false,
    }

    const [approveForm, setApproveForm] = React.useState(initialState)

    const selectOptions = React.useMemo(() => ([
        { label: aStatus, value: aStatus },
        { label: "Reject", value: 'reject' }
    ]), [aStatus]);
    const selectOptionsForMultipleAccept = React.useMemo(() => ([
        { label: canAcceptMultipleQuestions ? "Accept" : "Approve", value: canAcceptMultipleQuestions ? "Accept" : "Approve" },
        { label: "Reject", value: 'reject' }
    ]), [canAcceptMultipleQuestions]);

    const handleDialogToggle = (qId, status) => {
        setDialogStatus({
            ...dialogStatus,
            status: !dialogStatus.status,
            qId: !!qId ? qId : null
        });
    }
    const [questionInstructionModalStatus, setQuestionInstructionModalStatus] = React.useState(false);

    const handleQuestionInstructionModalToggle = () => {
        setQuestionInstructionModalStatus(!questionInstructionModalStatus);
    }

    const navigateToQuestionCreationPage = () => {
        navigate(`/question-details?reqId=${taskId}`, { state: allData })
    }

    React.useEffect(() => {
        console.log(selectquestions);
    }, [selectquestions])

    var downQues = [];

    const handleDialogToggleRev = (qId, status) => {

        setDialogStatus({
            ...dialogStatus,
            revStatus: !dialogStatus.revStatus,
            qId: !!qId ? qId : null
        });
    }
    const loadSelectOptions = (list) => list.map(el => <MenuItem value={el.value} key={el.value}>{el.label}</MenuItem>)

    const handleDialogToggleApprove = (qId, status, adminStatus) => {

        if (!!adminStatus) {
            setAStatus("Approve");
        }
        else if (adminStatus === null) {
            setAStatus("Accept")
        }
        if (status !== 'InProgress' && status !== 'completed') {
            setDialogStatus({
                ...dialogStatus,
                approveStatus: !dialogStatus.approveStatus,
                qId: !!qId ? qId : null
            })
        }
    }

    const dialogActionFn = () => {
        deleteQuestion(dialogStatus.qId)
        setDialogStatus({
            ...dialogStatus,
            status: false,
            qId: null,
        })
    }

    const dialogActionFnReject = () => {
        approveRejectQuestion(dialogStatus.qId, "Rejected", selectedFlag);
        setDialogStatus({
            ...dialogStatus,
            approveStatus: false,
            qId: null,
        })
    }

    const dialogActionFnRev = () => {
        SentForReview(dialogStatus.qId)
        setDialogStatus({
            ...dialogStatus,
            revStatus: false,
            qId: null,
        })
    }

    const dialogActionFnApprove = () => {
        if (aStatus === "Approve" && !approveForm.isMultiple)
            approveRejectQuestion(dialogStatus.qId, "Approved");

        else if (aStatus === "Accept" && !approveForm.isMultiple)
            approveRejectQuestion(dialogStatus.qId, "Accepted");

        if (approveForm.isMultiple) {
            approveRejectQuestion(dialogStatus.qId, approveForm.selectFor === "Accept" ? "Accepted" : "Approved");
        }
        setDialogStatus({
            ...dialogStatus,
            approveStatus: false,
            qId: null,
        })

    }

    const isReviewerAns = async (questionId) => {

        try {
            const res = await instance.get(`${getApiUrl('reviewerAnswer')}?qId=${questionId}`);
            if (res.data.status) return true;
            else return false;

        } catch (err) {
            console.log("error occured while getting reviewer ans", err);
        }
    }

    const handleDialogActionFnApproveReviewer = () => {
        approveRejectQuestionReviewer(dialogStatus.qId, "Approved");
        if (comment.length > 0) {
            setDialogStatus({
                ...dialogStatus,
                reviewerStatus: false,
                qId: dialogStatus.qId,
            })
        }
        else {
            setDialogStatus({
                ...dialogStatus,
                reviewerStatus: true,
                qId: dialogStatus.qId,
            })
        }
    }

    const dialogActionFnApproveReviewer = async () => {

        if (user.role === "SME" && allData.taskType === "ForReview" && allData.questionType === "KNOWLEDGE BASED QUESTIONS" && allData.questionList?.length > 0) {
            const currentQuestion = allData.questionList.find((question) => question.questionId === dialogStatus.qId);
            if (currentQuestion.questionType === "MCQ" || currentQuestion.questionType === "MCA") {
                try {
                    const res = await instance.get(`${getApiUrl('reviewerAnswer')}?qId=${dialogStatus.qId}`);
                    if (!res.data.answer) {
                        dispatch(openSnackBar({
                            msg: "Save the option",
                            type: 'error'
                        }))
                    }
                    else {
                        handleDialogActionFnApproveReviewer();
                    }

                } catch (err) {
                    console.log("error occured while getting reviewer ans", err);
                }
            }
            else {
                handleDialogActionFnApproveReviewer();
            }
        }
        else {
            handleDialogActionFnApproveReviewer();
        }
    }

    const dialogActionFnRejectReviewer = () => {
        approveRejectQuestionReviewer(dialogStatus.qId, "Rejected", selectedFlag);
        if (comment.length > 0) {
            setDialogStatus({
                ...dialogStatus,
                reviewerStatus: false,
                qId: dialogStatus.qId,
                // flagStatus: selectedFlag
            })
        }
        else {
            setDialogStatus({
                ...dialogStatus,
                reviewerStatus: true,
                qId: dialogStatus.qId,
            })
        }
    }

    const approveRejectQuestion = (qid, status, flag) => {
        let questions = [qid];
        const dataToSend = { status: status, questionId: approveForm.isMultiple ? selectquestions : questions, comment: comment, flagStatus: flag };
        instance.post(`${getApiUrl('approveCodelysisQuestion')}`, dataToSend).then(function (response) {
            if (response.data) {
                // for (var i = 0; i < data.length; i++) {
                //     if (data[i].questionId === qid)
                //         data[i].status = "sentForReview";
                // }
                reloadDashboard(false);
                setComment("");
                if (status === "Approved") {
                    dispatch(openSnackBar({
                        msg: "This question is now approved.",
                        type: 'success'
                    }))
                }
            }
            else {
                reloadDashboard(false);
                dispatch(openSnackBar({
                    msg: "This question is now rejected.",
                    type: 'success'
                }))
            }
        });
        setApproveForm(initialState)
        setSelectedFlag('');
        setComment("");
    }

    const approveRejectQuestionReviewer = (qid, status, flag) => {
        if (comment.length > 0) {
            const dataToSend = { status: status, comment: comment, questionId: [qid], assignedTo: user.userId, flagStatus: flag };
            instance.post(`${getApiUrl('saveReviewerStatus')}`, dataToSend).then(function (response) {
                if (response.data) {
                    setComment("");
                    dashboardData();
                    dispatch(openSnackBar({
                        msg: "This question review is sent to admin.",
                        type: 'success'
                    }))
                } else {
                    // dispatch(openSnackBar({
                    //     msg: "The question review status is sent as rejected to admin.",
                    //     type: 'success'
                    // }))
                    reloadDashboard(false);
                }
            });
        }
        else {
            setErrorMsg(true);
        }
    }


    const handleReviewTimelineModalToggle = (id, status) => {
        setReviewTimelineModalStatus(!reviewTimelineModalStatus);
        setTimeLineQuestionId(id);
        setTimeLineQuestionStatus(status);
    }

    const approveRejectQuestionToggleReviewer = (qId, targetDateExceeded, isCodeAvailable) => {
        if (!isCodeAvailable) {
            dispatch(openSnackBar({
                msg: "Please save a code before Approving or Rejecting a Question",
                type: 'error'
            }))
        }
        else if (targetDateExceeded) {
            dispatch(openSnackBar({
                msg: "Target data has Exceeded",
                type: 'error'
            }))
        } else {
            handleDialogToggleReviewer(qId);
        }
    }

    const handleDialogToggleReviewer = (qId) => {
        setAStatus("Approve")
        setDialogStatus({
            ...dialogStatus,
            reviewerStatus: !dialogStatus.reviewerStatus,
            qId: !!qId ? qId : null
        })
        setErrorMsg(false);
    }


    const reloadDashboard = (status) => {
        dashboardData(null);
    }


    const dashboardData = async (flag) => {
        if (user.role !== 'SME') {
            if ((state === null && flag === null) || state?.isForTopic) {
                try {
                    setIsAllDataLoading(false);
                    const res = await instance.get(`${getApiUrl('getCodelysisQuestionByTaskId')}/${taskId}?smeId=${sme.userId}`);
                    if (!!res.data) {
                        setAllData(res.data);
                    }
                } catch (error) {
                    appError('QuestionLibrary', error, dispatch);
                }
            }
            else {
                instance.get(`${getApiUrl('getCodelysisQuestionByTaskId')}/${taskId}?smeId=${state.smeId}`)
                    .then(function (response) {
                        if (response.data) {
                            setAllData(response.data);
                            //setIsLoading(false);
                        }
                    })
                    .catch(function (error) {
                        appError('QuestionLibrary', error, dispatch);
                    });
            }
        }
        else {
            if (state === null || state?.isForTopic) {
                instance.get(`${getApiUrl('getCodelysisQuestionByTaskId')}/${taskId}?smeId=${userId}`)
                    .then(function (response) {
                        if (response.data) {
                            setAllData(response.data);
                            //setIsLoading(false);
                        }
                    })
                    .catch(function (error) {
                        appError('QuestionLibrary', error, dispatch);
                    });
            }
            else {
                instance.get(`${getApiUrl('getCodelysisQuestionByTaskId')}/${taskId}?smeId=${user.userId}`)
                    .then(function (response) {
                        if (response.data) {
                            setAllData(response.data);
                            //setIsLoading(false);
                        }
                    })
                    .catch(function (error) {
                        appError('QuestionLibrary', error, dispatch);
                    });
            }
        }
    }

    const requestType = (state !== null && !state?.isForTopic) ? 'review' : '';

    React.useEffect(() => {
        allData === null && isAllDataLoading && dashboardData(allData);
        if (!!allData && allData.questionList) {
            setNOOfQuestion(allData.questionList.length);
            allData.questionList.map(el => {
                questionStatusMap.set(el.questionId, el.creatorStatus);
                setquestionStatusMap(new Map(questionStatusMap));
            })
        }
        dispatch(disselectALl());
        if (render && !!allData && !!allData.questionList) {
            allData.questionList.map(el => {
                setQuestion((prevState) => [...prevState, el.questionId]);
            })
            setRender(0);
        }

    }, [allData])


    const handleModalToggle = (id, status, adminStatus, isMultiReview) => {
        if (!isMultiReview) {
            setReviewModalStatus(!reviewModalStatus);
            setTimeLineQuestionId(id);
            setTimeLineQuestionStatus(status);
            setTimelineAdminQuestionStatus(adminStatus);
            setMultipleQuestionAllocate(isMultiReview);
        }

        if (isMultiReview) {
            var isValid = true;
            allData.questionList.forEach((question) => {
                if (selectquestions.includes(question.questionId)) {
                    if (!checkStatusForAllocation(question)) {
                        isValid = false;
                    }
                }
            })

            if (isValid) {
                setReviewModalStatus(!reviewModalStatus);
                setTimeLineQuestionId(id);
                setTimeLineQuestionStatus(status);
                setTimelineAdminQuestionStatus(adminStatus);
                setMultipleQuestionAllocate(isMultiReview);

                allData.questionList.map(el => {
                    questionStatusMap.set(el.questionId, el.Status);
                    setquestionStatusMap(new Map(questionStatusMap));
                    questionAdminStatusMap.set(el.questionId, el.adminStatus);
                    setquestionAdminStatusMap(new Map(questionAdminStatusMap));
                    questionReviewerStatusMap.set(el.questionId, el.reviewerStatus);
                    setquestionReviewerStatusMap(new Map(questionReviewerStatusMap));
                })

                for (let element in selectquestions) {
                    if ((questionReviewerStatusMap.get(selectquestions[element]) === "InProgress") || (questionReviewerStatusMap.get(selectquestions[element]) === "Approved") || questionAdminStatusMap.get(selectquestions[element]) === null || questionAdminStatusMap.get(selectquestions[element]) === "Completed") {
                        setCanAddReviewer(false);
                        break;
                    }
                    else {
                        setCanAddReviewer(true)
                    }
                }
            } else {
                dispatch(openSnackBar({
                    msg: "Some of the selected questions are not in state of Allocation",
                    type: 'error'
                }))
            }
        }

    }

    function selectAll() {
        if (!SelectAll) {
            setSelectAll(true);
            setSelectedRows(question);
            dispatch(setRows(question))
        } else {
            setSelectAll(false);
            setSelectedRows([]);
            dispatch(disselectALl());
        }
    };

    const handleCheckBoxChange = (e) => {
        downQues.push(e.target.value);
        setDownloadQuestions(downQues);
        if (noofquestion !== selectquestions) setSelectAll(false)
        if (selectquestions.includes(Number(e.target.value))) {
            dispatch(diSet(Number(e.target.value)))
        } else {
            dispatch(setRows(Number(e.target.value)))
        }

        if (noofquestion === selectquestions) setSelectAll(true)
        const questionId = (e.target.value)
        setCheckedQuestionMap({
            ...checkedQuestionMap,
            [questionId]: !checkedQuestionMap[questionId]
        })
        if (e.target.Checked) {
            setCheckedQuestion([...checkedQuestion, questionId]);
        }
        else {
            setCheckedQuestion(checkedQuestion.filter((element) => {
                return element !== questionId;
            }))
        }
    }

    const loadQuestions = () => {
        if (!!allData.questionList) {
            return allData.questionList.map(el => {
                return <QuestionLibraryItem data={el} key={el.questionId} taskId={taskId} handleModalToggle={handleModalToggle} handleReviewTimelineModalToggle={handleReviewTimelineModalToggle} reloadDashboard={reloadDashboard} deleteQuestion={handleDialogToggle} SentForReview={handleDialogToggleRev} approveRejectQuestion={handleDialogToggleApprove} approveRejectQuestionReviewer={approveRejectQuestionToggleReviewer} requestType={allData.taskType} handleCheckBoxChange={handleCheckBoxChange} isChecked={selectedRows.includes(String(el.questionId))} questionType={el.questionType} selectedFlag={selectedFlag} />
            })
        }

        return (
            <div className='cm-home-dash-widget-content cm-empty-home-dash-widget d-flex align-items-center justify-content-center'>
                <div className='py-4 text-center px-4'>
                    <EmptyIcon />
                    <p className='text-blue-700 mt-3 mb-0 fw-semibold'>Nothing to see!</p>
                    <p className='text-blue-gray-500 mt-2 mb-0 cm-sm-txt mt-2 mx-auto' style={{ 'maxWidth': '368px' }}>You can start by adding question.</p>
                </div>
            </div>
        )
    }

    const deleteQuestion = (qid) => {
        instance.get(`${getApiUrl('deleteCodelysisQuestion')}/${qid}`)
            .then(function (response) {
                if (response.data) {
                    reloadDashboard(false);
                    dispatch(openSnackBar({
                        msg: "This question is now deleted.",
                        type: 'success'
                    }))
                };
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const SentForReview = (qid) => {
        instance.get(`${getApiUrl('codelysisQuestionSendForReview')}/${qid}`).then(function (response) {
            if (response.data) {
                // for (var i = 0; i < data.length; i++) {
                //     if (data[i].questionId === qid)
                //         data[i].status = "sentForReview";
                // }
                reloadDashboard(false);
                dispatch(openSnackBar({
                    msg: "This question is now sent for review",
                    type: 'success'
                }))
            }

        })
            .catch(function (error) {
                console.log(error);
            });
    }

    const handleReportDownload = () => {
        let canDownload = 0;
        for (let element in selectquestions) {
            if (questionStatusMap.get(selectquestions[element]) !== "ReadyForAdmin") {
                canDownload = 0
                break;
            }
            else {
                canDownload = 1;
            }
        }
        if (canDownload) {
            saveAs(`${getApiUrl('downloadQuestions')}?questionIds=${selectquestions.join(",")}`, `Coding.xls`);
        }
        else {
            dispatch(openSnackBar({
                msg: "Some of the Selected Question are not ready to download",
                type: 'error'
            }))
        }
    }

    const handleReportMultipleChoiceDownload = async () => {
        // window.open(`${getApiUrl('downloadMcqQuestions')}?questionIds=${selectedRows.join(",")}`);
        let canDownload = 0;
        for (let element in selectquestions) {

            if (questionStatusMap.get(selectquestions[element]) !== "ReadyForAdmin") {
                canDownload = 0;
                break;
            }
            else if (questionStatusMap.get(selectquestions[element]) === "ReadyForAdmin") {
                canDownload = 1;
            }
        };
        if (canDownload) {
            saveAs(`${getApiUrl('downloadMultipleChoiceQuestions')}?questionIds=${selectquestions.join(",")}&topic=${state?.topic}`, `MultipleChoice.xls`);
        }
        else {
            dispatch(openSnackBar({
                msg: "Some of the Selected Question are not Ready for Download",
                type: 'error'
            }))
        }
    }

    const handleApproveChange = (e, key) => {
        setApproveForm({
            ...approveForm,
            [key]: e.target.value,
            isSelected: true,
        })
    }

    const checkStatusForAcceptReject = (question) => {
        if (question.creatorStatus === "SentForReview" && (question.adminStatus === null || question.reviewerStatus !== "InProgress")) {
            return true;
        }
        return false;
    }

    const checkStatusForAllocation = (question) => {
        if (question.creatorStatus === "SentForReview" && question.adminStatus === "Accepted") {
            return true;
        }
        return false;
    }

    const handleAcceptMultipleQuestion = () => {
        // checking whether allowed to accept/reject or approve/reject or not
        var isValid = true;
        allData.questionList.forEach((question) => {
            if (selectquestions.includes(question.questionId)) {
                if (!checkStatusForAcceptReject(question)) {
                    isValid = false;
                }
            }
        })

        if (isValid) {
            let canAcceptMultiple = true;
            let canApproveMultiple = true;
            let isRejected = false;
            let acceptedQuestions = []
            allData.questionList.forEach((question) => {
                if (selectquestions.includes(question.questionId)) {
                    if (question.adminStatus !== null) {
                        canAcceptMultiple = false;
                        acceptedQuestions.push(question.questionId);
                    }
                    if (question.adminStatus !== "Accepted") {
                        canApproveMultiple = false;
                    }

                    if (question.adminStatus === "Rejected" || question.adminStatus === "Approved") {
                        isRejected = true;
                    }
                }
            })
            if (canAcceptMultiple) {
                setCanAcceptMultipleQuestions(true);
            }
            else {
                setCanAcceptMultipleQuestions(false);
            }

            if (canApproveMultiple) {
                setCanApproveMultipleQuestion(true);
            }
            else {
                setCanApproveMultipleQuestion(false)
            }
            if (isRejected) {
                setApproveForm({
                    ...approveForm,
                    isRejected: true,
                    isMultiple: true,
                })
            }
            else {
                setApproveForm({
                    ...approveForm,
                    isRejected: false,
                    isMultiple: true,
                })
            }
            handleDialogToggleApprove(null, "sent For Review", null)
        } else {
            dispatch(openSnackBar({
                msg: "Some of the selected questions are not in state of Accept/Reject or Approve/Reject",
                type: 'error'
            }))
        }
    }


    const handleComment = (e) => {
        setComment(e.target.value)
    }

    const handleError = () => chkError.location === 'QuestionLibrary'

    const handleSelectShow = () => {
        setFlagOptions(false);
    }

    const handleSelectClose = () => {
        setApproveForm(initialState)
        setFlagOptions(true);
        setSelectedFlag('');
        setComment("");
    }

    const handleSelectChange = (selected) => {
        setSelectedFlag(selected.value);
    }

    return (
        handleError() ? <ErrorController data={chkError} /> : <div className='cm-quest-lib-container mt-4 pb-5'>
            <MetaComp title="Question Library - Mercer | Mettl" />
            <div className="cm-quest-lib-top-cards d-flex align-items-center justify-content-start">
                {
                    !!allData ?
                        <>
                            <div className='cm-quest-lib-top-item bg-white'>
                                <span className='cm-gradient-txt cm-mmc-font'>{allData.totalQuestion}</span>
                                <p className='my-0 d-flex align-items-center fw-medium text-blue-gray-700 pt-2'><FontAwesomeIcon icon={regular("circle-question")} className="me-2 text-blue-gray-700" /> Question Count</p>
                            </div>
                            {allData.taskType === "ForCreation" && <div className='cm-quest-lib-top-item bg-white'>
                                <span className='cm-gradient-txt cm-mmc-font cm-truncate-1' title={allData.topicName}>{allData.topicName}</span>
                                <p className='my-0 d-flex align-items-center fw-medium text-blue-gray-700 pt-2'><FontAwesomeIcon icon={regular("tag")} className="me-2 text-blue-gray-700" /> Topic</p>
                            </div>}
                            {allData.taskType === "ForCreation" && <div className='cm-quest-lib-top-item bg-white'>
                                <span className='cm-gradient-txt cm-mmc-font'>{allData.difficultyLevel !== null ? allData.difficultyLevel : "Mixed"}</span>
                                <p className='my-0 d-flex align-items-center fw-medium text-blue-gray-700 pt-2'><FontAwesomeIcon icon={regular("chart-line")} className="me-2 text-blue-gray-700" /> Level</p>
                            </div>}
                            <div className='cm-quest-lib-top-item bg-white'>
                                <span className='cm-gradient-txt cm-mmc-font'>{allData.status}</span>
                                <p className='my-0 d-flex align-items-center fw-medium text-blue-gray-700 pt-2'><FontAwesomeIcon icon={regular("circle-dot")} className="me-2 text-blue-gray-700" /> Status</p>
                            </div>
                        </>
                        : null
                }
            </div>
            <div className="cm-candidate-report-list-container mt-4">
                <>
                    <ViewReviewerModal t={t} reviewModalStatus={reviewModalStatus} handleModalToggle={handleModalToggle} timelineQuestionId={timelineQuestionId} dashboardData={dashboardData} timelineQuestionStatus={timelineQuestionStatus} timelineAdminQuestionStatus={timelineAdminQuestionStatus} isMultiReview={multipleQuestionAllocate} checkedQuestion={selectquestions} setCheckedQuestion={setCheckedQuestion} checkedQuestionMap={checkedQuestionMap} setCheckedQuestionMap={setCheckedQuestionMap} resetQuestionMap={resetQuestionMap} canAddReviewerMultiple={multipleQuestionAllocate ? canAddReviewer : true} questionReviewerStatusMap={questionReviewerStatusMap} />
                    <ReviewTimelineModal t={t} reviewModalStatus={reviewTimelineModalStatus} handleModalToggle={handleReviewTimelineModalToggle} timelineQuestionId={timelineQuestionId} timelineQuestionStatus={timelineQuestionStatus} />
                    <div className="w-100 cm-candidate-report-list-header d-flex align-items-center bg-blue-gray-50">
                        <input div className='col-sm-1' style={{ width: "3%" }}
                            type="checkbox"
                            checked={!render && selectquestions.length === noofquestion}
                            onClick={selectAll}
                        />
                        <div className='d-flex align-items-center justify-content-center' style={{width: "8%"}}>
                            <p className='my-2 cm-sm-txt fw-medium text-blue-gray-700'>Question Id</p>
                        </div>
                        <div className='d-flex  align-items-center justify-content-center' style={{width: "15%"}}>
                            <p className='my-2 cm-sm-txt fw-medium text-blue-gray-700'>Status</p>
                        </div>
                        <div className='d-flex align-items-center justify-content-center' style={{width: "15%"}}>
                            <p className='my-2 cm-sm-txt fw-medium text-blue-gray-700'>Title</p>
                        </div>
                        <div className='d-flex align-items-center justify-content-center' style={{width: "17%"}}>
                            <p className='my-2 cm-sm-txt fw-medium text-blue-gray-700'>Admin Status </p>
                        </div>
                        <div className='d-flex align-items-center justify-content-center' style={{width: "17%"}}>
                            <p className='my-2 cm-sm-txt fw-medium text-blue-gray-700'>Review Status</p>
                        </div>

                        <div className='d-flex align-items-center justify-content-between' style={{width: "25%"}}>
                            <div className='d-flex align-items-center justify-content-center' style={{width : '50%'}}>
                            <p className='my-2 cm-sm-txt fw-medium text-blue-gray-700'>Actions</p>
                            </div>
                            
                            <div className='d-flex align-items-center justify-content-end' style={{width : '50%'}}>
                                {allData?.taskType === "ForCreation" &&
                                    <>
                                        {user.role === 'ADMIN' &&
                                            <CustomToolTip title={"Accept/Reject Multiple Questions"}>
                                                <span
                                                    className={`text-action-blue cm-sm-txt fw-medium px-3 py-1 cm-pointer ${selectquestions.length > 0
                                                        ? ""
                                                        : "cm-sidebar-btn-disabled"
                                                        }`}
                                                    onClick={() => {
                                                        handleAcceptMultipleQuestion(selectquestions)
                                                    }
                                                    }
                                                >
                                                    <FontAwesomeIcon
                                                        icon={regular("comments-question-check")}
                                                    />
                                                </span>
                                            </CustomToolTip>
                                        }
                                        {user.role === 'ADMIN' &&
                                            <CustomToolTip title={"Allocate Multiple"}>
                                                <span
                                                    className={`text-action-blue cm-sm-txt fw-medium px-3 py-1 cm-pointer ${selectquestions.length > 0
                                                        ? ""
                                                        : "cm-sidebar-btn-disabled"
                                                        }`}
                                                    onClick={() =>
                                                        handleModalToggle(0, "SentForReview", "Accepted", true)
                                                    }
                                                >
                                                    <FontAwesomeIcon icon={regular("link")} />
                                                </span>
                                            </CustomToolTip>
                                        }
                                        {user.role === 'ADMIN' &&
                                            <CustomToolTip title={"Check Plagiarism"}>
                                                <span
                                                    className={`text-action-blue cm-sm-txt fw-medium px-3 py-1 cm-pointer  ${selectquestions.length > 0
                                                        ? ""
                                                        : "cm-sidebar-btn-disabled"
                                                        }`}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={regular("file-magnifying-glass")}
                                                    />
                                                </span>
                                            </CustomToolTip>
                                        }

                                        {user.role === 'SME' && allData?.status !== "Completed" &&
                                            <div onClick={handleQuestionInstructionModalToggle} className='cm-add-quest-btn border border-blue-gray-300 bg-white text-blue-800 cm-sm-txt fw-medium cm-pointer'>
                                                <FontAwesomeIcon icon={regular("plus")} className="pe-2" />
                                                Add Question
                                            </div>

                                            // <Link to={`/question-details?reqId=${taskId}`} state={allData} className='cm-add-quest-btn border border-blue-gray-300 bg-white text-blue-800 cm-sm-txt fw-medium cm-pointer'>
                                            //     <FontAwesomeIcon icon={regular("plus")} className="pe-2" />
                                            //     Add Question
                                            // </Link>
                                        }
                                        {user.role !== 'SME' &&
                                            <CustomToolTip title="Download Questions">
                                                <span
                                                    className={`ms-3 cm-report-download-btn ${selectquestions.length > 0 ? 'text-blue-gray-700' : 'text-gray-300'}`}
                                                    // onClick={selectedRows.length > 0 ? handleReportDownload : null}
                                                    onClick=
                                                    {allData && allData.questionType === "Codelysis" && selectquestions.length > 0 ? handleReportDownload : handleReportMultipleChoiceDownload}                                                >
                                                    <FontAwesomeIcon icon={regular("circle-down")} />
                                                </span>
                                            </CustomToolTip>
                                        }
                                    </>
                                }
                                {user.role === 'SME' && allData?.taskType !== "ForCreation" &&
                                    <CustomToolTip title={"Review Multiple"}>
                                        {selectquestions.length > 0 && allData && allData.questionType === "KNOWLEDGE BASED QUESTIONS" ?
                                            (<Link
                                                to={`/review-knowledgeBased-question/${taskId}`}
                                                state={{ singleReview: false }}
                                                className={`text-action-blue d-inline cm-sm-txt fw-medium px-3 py-1 cm-pointer`}
                                            >
                                                <FontAwesomeIcon icon={regular("eye")} />
                                            </Link>)
                                            : (
                                                <span
                                                    className={`text-action-blue d-inline cm-sm-txt fw-medium px-3 cm-pointer cm-sidebar-btn-disabled`}
                                                >
                                                    <FontAwesomeIcon icon={regular("eye")} />
                                                </span>
                                            )
                                        }
                                    </CustomToolTip>
                                }
                            </div>
                        </div>

                    </div>

                    {<div className="cm-candidate-report-list-content bg-white">
                        {!!allData && loadQuestions()}
                    </div>}

                    {allData && allData.questionType === "KNOWLEDGE BASED QUESTIONS" && <QuestionAddModal modalStatus={questionInstructionModalStatus} handleModalToggle={handleQuestionInstructionModalToggle} navigateToQuestionCreationPage={navigateToQuestionCreationPage} allData={allData} taskId={taskId} />}
                    {allData && allData.questionType === "Codelysis" && <QuestionInstructionModal modalStatus={questionInstructionModalStatus} handleModalToggle={handleQuestionInstructionModalToggle} navigateToQuestionCreationPage={navigateToQuestionCreationPage} allData={allData} />}
                    {/* <QuestionAddModal modalStatus={questionInstructionModalStatus} handleModalToggle={handleQuestionInstructionModalToggle} navigateToQuestionCreationPage={navigateToQuestionCreationPage} allData={allData} /> */}
                    <CustomDialog
                        dialogHeading="Remove Question"
                        cancelBtnLabel="No, Don't Delete"
                        actionBtnLabel={<span>Delete Question</span>}
                        actionBtnBg="bg-danger"
                        cancelFn={handleDialogToggle}
                        actionFn={dialogActionFn}
                        dialogStatus={dialogStatus.status}
                    >
                        <p className='my-0 cm-sm-txt text-blue-800'>Are you sure you want to remove the selected question? All the data defined in the question, will be removed as well.</p>
                    </CustomDialog>


                    <CustomDialog
                        dialogHeading="Send for review"
                        cancelBtnLabel="Cancel"
                        actionBtnLabel={<span>Send for Review</span>}
                        actionBtnBg="bg-action-blue"
                        cancelFn={handleDialogToggleRev}
                        actionFn={dialogActionFnRev}
                        dialogStatus={dialogStatus.revStatus}
                    >
                        <p className='my-0 cm-sm-txt text-blue-800'>Are you sure you want to send the selected question for review?</p>
                    </CustomDialog>

                    <CustomDialog
                        dialogHeading={aStatus + " or Reject Question"}
                        cancelBtnLabel="Cancel"
                        actionBtnLabel={(approveForm.selectFor === 'Approve' || approveForm.selectFor === 'Accept') ? (approveForm.selectFor === 'Approve' ? <span>Approve</span> : <span>Accept</span>) : false}
                        actionBtnLabel2={approveForm.selectFor === 'reject' ? <span>Reject</span> : false}
                        actionBtnBg="bg-action-blue"
                        actionBtnBg2="bg-danger"
                        cancelFn={handleDialogToggleApprove}
                        actionFn={dialogActionFnApprove}
                        actionFn2={dialogActionFnReject}
                        dialogStatus={dialogStatus.approveStatus}
                        isReviewer="false"
                        selectedFlag={approveForm.selectFor === 'reject' ? selectedFlag : false}
                        handleSelectClose={handleSelectClose}
                    >
                        {/* changes  */}
                        <div className='cm-create-task-form px-3 pb-2'>
                            <label htmlFor="Accept/Reject" className='pb-2 text-blue-gray-700 cm-xs-txt fw-medium'>Select :</label>
                            <Muiselect
                                id="request-select mb-2"
                                value={approveForm.selectFor}
                                onChange={val => handleApproveChange(val, 'selectFor')}
                                disabled={(canAcceptMultipleQuestions == canApproveMultipleQuestion) && approveForm.isMultiple}
                            >
                                {loadSelectOptions(approveForm.isMultiple ? selectOptionsForMultipleAccept : selectOptions)}
                            </Muiselect>
                            {canAcceptMultipleQuestions === canApproveMultipleQuestion && approveForm.isMultiple &&
                                <div className="alert">
                                    <label className="cm-xs-txt fw-medium">
                                        {approveForm.isRejected ? "Some of the selected questions were already Approved/Rejected" : " Please select questions of same Admin status to proceed"}
                                    </label>
                                </div>}
                        </div>
                        {approveForm.selectFor === 'reject' &&

                            <div className="cm-create-task-for px-3 pb-2" >
                                <label htmlFor="Accept/Reject" className='pb-2 text-blue-gray-700 cm-xs-txt fw-medium'>Choose a Flag</label>
                                <Select
                                    className='optionsDropDown'
                                    options={options}
                                    placeholder="---Select a Flag---"
                                    onChange={handleSelectChange}
                                />


                                {/* {flagOptions ?
                                    <FontAwesomeIcon
                                        icon={faFlag}
                                        className='flagIcon'
                                        style={{ fontSize: '20px' }}
                                        onClick={handleSelectShow}
                                    />
                                    : <FontAwesomeIcon
                                        icon={faXmark}
                                        className='flagIcon'
                                        style={{ fontSize: '20px' }}
                                        onClick={handleSelectClose}
                                    />
                                } */}

                            </div>
                        }
                        {(approveForm.isSelected) &&
                            <div className='px-3'>
                                <p className='text-blue-gray-500 cm-sm-txt fw-medium mb-1 ms-1'>Comment (optional)</p>

                                <div className='d-flex'>
                                    <TextareaAutosize
                                        maxRows={12}
                                        minRows={4}
                                        className="text-blue-800 cm-sm-txt p-2"
                                        aria-label="maximum height"
                                        placeholder={approveForm.selectFor === 'reject' ? "State reasons for raising the flag" : "Write your comment"}
                                        value={comment}
                                        style={{
                                            'width': '750px', 'border': '1px solid var(--bs-blue-gray-100)', '&:hover': {
                                                borderColor: '#FFFFFF',
                                                borderWidth: 2
                                            },
                                        }}
                                        onChange={handleComment}
                                    />
                                </div>
                            </div>}
                    </CustomDialog>


                    <CustomDialog
                        dialogHeading="Approve or Reject Question"
                        cancelBtnLabel="Cancel"
                        actionBtnLabel={(approveForm.selectFor === 'Approve') ? <span>Approve</span> : false}
                        actionBtnLabel2={(approveForm.selectFor === 'reject') ? <span>Reject</span> : false}
                        actionBtnBg="bg-action-blue"
                        actionBtnBg2="bg-danger"
                        cancelFn={handleDialogToggleReviewer}
                        actionFn={dialogActionFnApproveReviewer}
                        actionFn2={dialogActionFnRejectReviewer}
                        dialogStatus={dialogStatus.reviewerStatus}
                        isReviewer="true"
                        selectedFlag={approveForm.selectFor === 'reject' ? selectedFlag : false}
                        handleSelectClose={handleSelectClose}
                        isComment={comment}
                    >
                        {/* <p className='text-danger fw-semibold txt-sm my-0 pb-2'>Please save a code before Approving or Rejecting a Question</p> */}
                        {errorMsg && <p className='text-danger fw-semibold txt-sm my-0 pb-2'>Review report is required</p>}
                        <div className='cm-create-task-form px-3 pb-2'>
                            <label htmlFor="Accept/Reject" className='pb-2 text-blue-gray-700 cm-xs-txt fw-medium'>Select :</label>
                            <Muiselect
                                id="request-select mb-2"
                                value={approveForm.selectFor}
                                onChange={val => handleApproveChange(val, 'selectFor')}
                            >
                                {loadSelectOptions(selectOptions)}
                            </Muiselect>
                        </div>
                        {approveForm.selectFor === 'reject' &&

                            <div className="cm-create-task-for px-3 pb-2" >
                                <label htmlFor="Accept/Reject" className='pb-2 text-blue-gray-700 cm-xs-txt fw-medium'>Choose a Flag</label>
                                <Select
                                    className='optionsDropDown'
                                    options={options}
                                    placeholder="---Select a Flag---"
                                    onChange={handleSelectChange}
                                />
                            </div>
                        }

                        {(approveForm.isSelected) &&
                            <div className='px-3'>
                                <p className='text-blue-gray-500 cm-sm-txt fw-medium mb-1 ms-1'>Review report</p>
                                <div className='d-flex'>
                                    <TextareaAutosize
                                        maxRows={12}
                                        minRows={4}
                                        className="text-blue-800 cm-sm-txt p-2"
                                        aria-label="maximum height"
                                        placeholder={approveForm.selectFor === 'reject' ? "State reasons for raising the flag" : "Write your comment"}
                                        value={comment}
                                        style={{
                                            'width': '750px', 'border': '1px solid var(--bs-blue-gray-100)', '&:hover': {
                                                borderColor: '#FFFFFF',
                                                borderWidth: 2
                                            },
                                        }}
                                        onChange={handleComment}
                                    />
                                </div>
                            </div>}
                    </CustomDialog>

                </>


                {/* {candReport.data.length === 0 && candReport.pageNum === 0 ? null : <CandidateReportPagination t={t} handlePagination={handlePagination} candidateData={candReport} /> } */}
            </div>


        </div>

    )

}

export default QuestionLibraryContainer;